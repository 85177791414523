import {useTheme} from '@rneui/themed';
import {Text} from '@rneui/themed';
import {floor} from 'lodash';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useWindowDimensions, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
// import {MathJaxSvg} from 'react-native-mathjax-html-to-svg';
import RenderHtml, {
  CustomBlockRenderer,
  domNodeToHTMLString, HTMLContentModel, HTMLElementModel,
} from 'react-native-render-html';
import WebView from 'react-native-webview';
import {useRecoilState, useRecoilValue} from 'recoil';

import Paper, {
  Annotation, Destination, getReferencePapers,
} from '../common/paper';
import {SortType} from '../common/store';
import ActivityIndicator from '../components/ActivityIndicator';
import {AppContext, NavigationContext} from '../context';
import {isWeb} from '../platform';
import {analytics} from '../platform/firebase';
import {uuid} from '../platform/misc';
import {htmlReaderServerAddrState, settingsState} from '../recoil/atoms';
import {currentPaperState} from '../recoil/atoms/papers';
import {isElectronState} from '../recoil/selectors';
import {AnnotationContext} from './PaperView';

type MessageData = {
  msg: 'load-success' | 'content-loaded' | 'outline' | 'destinations' |
    'annotations' | 'annotation-click' | 'highlight';
  payload: Record<string, unknown>;
};

const MathJaxRenderer: CustomBlockRenderer = (props) => {
  // eslint-disable-next-line react/prop-types
  const domNode = props.tnode.domNode;
  const html = React.useMemo(() => domNodeToHTMLString(domNode), [domNode]);
  const eqn = /alttext="([^"]*)"/gm.exec(html)?.[1];
  const isBlock = html.includes('display="inline"');
  return (
    <View style={{
      justifyContent: isBlock ? 'center' : 'flex-start',
    }}>
      <Text>{eqn}</Text>
      {/* <SvgUri
        uri={'https://latex.codecogs.com/svg.image?' + eqn}
        onError={(e) => {
          // console.log(e);
        }}
      /> */}
    </View>
  );
};

type PaperViewPropsType = {
  paper: Paper;
};

export const HTMLPaperViewer = ({
  paper,
}: PaperViewPropsType) => {
  const {
    onPaperOrderChanged,
  } = useContext(AppContext);
  const {
    highlights,
    updateHighlights,
    setCurrentHighlight,
  } = useContext(AnnotationContext);
  const {navigate} = useContext(NavigationContext);
  const [htmlContent, setHtmlContent] = useState<string>();
  const [ready, setReady] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<string>('Initializing Viewer...');

  const webViewRef = useRef<WebView>(null);
  const {width} = useWindowDimensions();
  const {theme} = useTheme();
  const [settings, setSettings] = useRecoilState(settingsState);
  const isElectron = useRecoilValue(isElectronState);
  const [destinations, setDestinations] =
    useState<Record<string, Destination>>({});
  const [referencePapers, setReferencePapers] = useState<Paper[]>([]);
  const htmlReaderServerAddr = useRecoilValue(htmlReaderServerAddrState);

  useEffect(() => {
    if (!paper) return;
    getReferencePapers(paper).then((papers) => setReferencePapers(papers));
  }, []);

  const postMessage = (msg: string, payload: Record<string, unknown>) => {
    webViewRef.current?.postMessage(JSON.stringify({
      msg, payload,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    }), '*');
  };

  useEffect(() => {
    if (ready && webViewRef.current && htmlContent) {
      postMessage('content', {
        content: htmlContent, isWeb, isElectron,
      });
      postMessage('highlights', {'highlights': highlights});
    }
  }, [htmlContent, webViewRef.current, ready]);

  useEffect(() => {
    console.log('post', highlights);
    postMessage('highlights', {'highlights': highlights});
  }, [highlights]);

  useEffect(() => {
    // if ((paper.dateOpened || 0) < Date.now() - 5000) {
    //   const _paper = {
    //     ...paper,
    //     dateOpened: Date.now(),
    //   };
    //   savePaperAndSync(_paper);
    // }
    analytics?.logEvent('read_paper_html', {
      paperId: paper.id,
      paperTitle: paper.title,
    });

    (!isWeb || isElectron) && paper.htmlUrl && fetch(paper.htmlUrl.replace('http://ar5iv.org/abs/', 'https://ar5iv.labs.arxiv.org/html/')).then(async (response) => {
      if (response.status !== 200) {
        return;
      }
      const html = await response.text();
      setHtmlContent(html);
    });

    return () => {
      onPaperOrderChanged(SortType.ByDateOpened);
    };
  }, []);

  useEffect(() => {
    postMessage('zoom', {'zoom': paper.zoom});
  }, [paper.zoom]);

  useEffect(() => {
    postMessage('highlights', {'highlights': highlights});
  }, [highlights]);

  /**
   * score
   * @param a - string to compare
   * @param b - string to compare
   * @returns - the Levenshtein distance between a and b
   */
  function score(q: string, p: Paper): number {
    if (!p.title) return 0;
    const nq = q.toLowerCase().replace(/\W/g, '');
    if (nq.indexOf(p.title.toLowerCase().replace(/\W/g, '')) !== -1) return 1;
    return 0;
  }

  return (
    <View testID="html_viewer_container" style={{flex: 1}}>
      {paper && (
        paper?.htmlUrl ? (
          settings.useWebViewForHtml ? <WebView
            bounces={false}
            showsHorizontalScrollIndicator={false}
            ref={webViewRef}
            // source={isWeb ? {html: htmlContent || ''} : {uri: paper.htmlUrl}}
            source={{
              // html: htmlContent || '',
              uri: htmlReaderServerAddr,
            }}
            // baseUrl: 'https://ar5iv.labs.arxiv.org',
            onNavigationStateChange={(event) => {
              // webViewRef.current?.stopLoading();
            }}
            onError={(e) => {
              console.log(e);
            }}
            onHttpError={(e) => {
              console.log(e);
            }}
            javaScriptEnabled={true}
            onMessage={(e) => {
              let data: MessageData;
              try {
                data = JSON.parse(e.nativeEvent.data) as MessageData;
              } catch (e) {
                return;
              }
              console.log('msg received', data);
              if (data.msg === 'load-success') {
                setReady(true);
                setStatus('Rendering Paper Content...');
              } else if (data.msg === 'content-loaded') {
                setLoading(false);
              } else if (data.msg === 'outline') {
                // client sent paper outline
              } else if (data.msg === 'destinations') {
                // client sent paper's destinations (for annotations)
                const destinations =
                    data.payload as Record<string, Destination>;
                setDestinations(destinations);
              } else if (data.msg === 'annotations') {
                // do nothing
              } else if (data.msg === 'annotation-click') {
                // user clicked on annotation
                const ann = data.payload as Annotation;
                const dest = destinations[ann.dest];
                const query = dest.text;
                if (query && referencePapers) {
                  const citedPapers = referencePapers
                      .map((p) => ({
                        paper: p,
                        score: score(query, p),
                      }))
                      .filter((p) => p.score > 0)
                      .sort((a, b) => b.score - a.score);
                  if (citedPapers.length >= 1) {
                    navigate('CollectionPreviewRoot', {
                      screen: 'PreviewPaperInfo',
                      params: {paper: citedPapers[0].paper},
                    });
                  }
                }
              } else if (data.msg === 'highlight') {
                const payload = data.payload as {
                  id: string,
                  section: {
                    id: string,
                    title: string,
                  },
                  text: string,
                  highlight: boolean,
                };
                if (payload.highlight) {
                  let posDesc = '';
                  if (payload.section.title) {
                    posDesc = `Section ${payload.section.title}`;
                  }
                  updateHighlights([
                    ...highlights,
                    {
                      id: uuid(),
                      content: {
                        text: payload.text as string,
                      },
                      position: {
                        id: payload.id as string,
                        description: posDesc,
                        paragraphId: payload.id as string,
                        sectionId: payload.section.id as string,
                        sectionTitle: payload.section.title as string,
                      },
                    },
                  ]);
                } else {
                  updateHighlights(highlights.filter(
                      (h) => h.position?.paragraphId !== payload.id));
                }
              } else if (data.msg === 'highlight-click') {
                const payload = data.payload as {
                  id: string,
                };
                setCurrentHighlight(
                    highlights.find((h) => h.id === payload.id) || null);
              } else {
                console.log(data);
              }
            }}
            scalesPageToFit={true} /> :
          <ScrollView style={{
            padding: 16,
            backgroundColor: theme.colors?.white,
          }} bounces={false}>
            <RenderHtml
              contentWidth={width}
              source={{
                html: htmlContent || '',
                baseUrl: 'https://ar5iv.labs.arxiv.org/'}}
              customHTMLElementModels={{
                math: HTMLElementModel.fromCustomModel({
                  tagName: 'math',
                  contentModel: HTMLContentModel.block,
                }),
              }}
              renderers={{
                math: MathJaxRenderer,
              }}
              tagsStyles={{
                body: {
                  fontSize: floor(16 * paper.zoom / 100),
                  backgroundColor: theme.colors?.white,
                },
                p: {
                },
              }}
              ignoredDomTags={['header', 'footer', 'semantics']}
              enableExperimentalGhostLinesPrevention={true}
              enableExperimentalBRCollapsing={true}
            />
          </ScrollView>
        ) : (
          <Text style={{padding: 16, textAlign: 'center'}}>
            Could not retrieve the paper. Please try again later.
          </Text>
        )
      )}
      {loading ?
        <ActivityIndicator center text={status} /> : <></>}
    </View>
  );
};

export const HTMLPaperViewScreen = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  const {theme} = useTheme();
  return (
    <View style={{height: '100%', backgroundColor: theme.colors?.background}}>
      {currentPaper && <HTMLPaperViewer paper={currentPaper} />}
    </View>
  );
};

export default HTMLPaperViewScreen;
