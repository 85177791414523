import {useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {LinearProgress, Text} from '@rneui/themed';
import React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Linking, View} from 'react-native';
// import Pdf from '../components/Pdf';
import {WebView} from 'react-native-webview';
import {useRecoilValue} from 'recoil';

import Paper, {
  Annotation, Destination, getReferencePapers, PaperOutline,
  PdfHighlight,
} from '../common/paper';
import {AppContext} from '../context';
import {MainStackParamList} from '../Main';
import {toastError} from '../platform/toast';
import {pdfReaderServerAddrState} from '../recoil/atoms';

type MessageData = {
  msg: 'load-success' | 'outline' | 'destinations' | 'annotations' |
    'annotation-click';
  params: unknown;
};


const Loading = ({p}: {p: number}) => {
  return <View style={{
    flex: 1,
    width: '100%',
    height: '100%',
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  }}>
    <View style={{
      flex: 1,
      width: '100%',
      height: '100%',
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <View style={{flex: 1, maxWidth: 300}}>
        <Text style={{marginBottom: 8, alignSelf: 'center'}}>
                  Downloading...
        </Text>
        <LinearProgress value={p} />
      </View>
    </View>
  </View>;
};

const Error = ({msg}: {msg: string}) => {
  return <View style={{
    flex: 1,
    width: '100%',
    height: '100%',
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
  }}>
    <View style={{
      flex: 1,
      width: '100%',
      height: '100%',
      padding: 16,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <View style={{flex: 1, maxWidth: 300}}>
        <Text style={{marginBottom: 8, alignSelf: 'center'}}>
          {msg}
        </Text>
      </View>
    </View>
  </View>;
};

type PdfViewerProps = {
  paper?: Paper;
  updateHighlights?: (highlights: PdfHighlight[]) => void;
};

const PdfWebViewer = ({
  paper, updateHighlights,
}: PdfViewerProps) => {
  const webView = useRef<WebView>(null);
  const {savePaperAndSync, isPaperInLibrary} = useContext(AppContext);
  const [destinations, setDestinations] =
    useState<Record<string, Destination>>({});
  const [referencePapers, setReferencePapers] = useState<Paper[]>([]);
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [uri, setUri] = useState<string>();
  const pdfReaderServerAddr = useRecoilValue(pdfReaderServerAddrState);

  useEffect(() => {
    if (!paper) return;
    getReferencePapers(paper).then((papers) => setReferencePapers(papers));
  }, []);

  useEffect(() => {
    if (!paper) return;
    if (!paper || !paper.pdfUrl) return;
    setUri(`${pdfReaderServerAddr}?${new URLSearchParams({
      url: paper.pdfUrl,
      zoom: paper.zoom.toString(),
    }).toString()}`);
  }, [paper, pdfReaderServerAddr]);

  /**
   * score
   * @param a - string to compare
   * @param b - string to compare
   * @returns - the Levenshtein distance between a and b
   */
  function score(q: string, p: Paper): number {
    if (!p.title) return 0;
    const nq = q.toLowerCase().replace(/\W/g, '');
    if (nq.indexOf(p.title.toLowerCase().replace(/\W/g, '')) !== -1) return 1;
    return 0;
  }

  return paper && uri ? <View style={{flex: 1, flexDirection: 'row'}}>
    <WebView
      ref={webView}
      style={{width: '100%', height: '100%'}}
      source={{uri: uri}}
      javaScriptEnabled={true}
      bounces={false}
      onLoadStart={() => setLoading(true)}
      onLoadEnd={() => {
        setLoading(false);
      }}
      onLoadProgress={(e) => {
        setLoadingProgress(e.nativeEvent.progress);
      }}
      onError={(_e) => {
        // toastError(e.nativeEvent.description);
      }}
      onHttpError={(e) => {
        toastError(e.nativeEvent.description);
      }}
      renderError={() => <Error msg="Error loading page" />}
      onMessage={(e) => {
        let data: MessageData;
        try {
          data = JSON.parse(e.nativeEvent.data) as MessageData;
        } catch (e) {
          return;
        }
        if (data.msg === 'load-success') {
          setLoading(false);
        } else if (data.msg === 'outline') {
          // client sent paper outline
          const outline = data.params as PaperOutline[];
          if (isPaperInLibrary(paper.id)) {
            savePaperAndSync({...paper, pdfInfo: {...paper.pdfInfo, outline}});
          }
        } else if (data.msg === 'destinations') {
          // client sent paper's destinations (for annotations)
          const destinations = data.params as Record<string, Destination>;
          setDestinations(destinations);
          // savePaperAndSync(
          //     {...paper, pdfInfo: {...paper.pdfInfo, destinations}});
        } else if (data.msg === 'annotations') {
          // do nothing
        } else if (data.msg === 'annotation-click') {
          // user clicked on annotation
          const ann = data.params as Annotation;
          const dest = destinations[ann.dest];
          const query = dest.text;
          if (query && referencePapers) {
            const citedPapers = referencePapers
                .map((p) => ({
                  paper: p,
                  score: score(query, p),
                }))
                .filter((p) => p.score > 0)
                .sort((a, b) => b.score - a.score);
            if (citedPapers.length >= 1) {
              navigation.push('CollectionPreviewRoot', {
                screen: 'PreviewPaperInfo',
                params: {paper: citedPapers[0].paper},
              });
            }
          }
        } else {
          console.log(data);
        }
      }}
      onNavigationStateChange={(e) => {
        if (e.url.startsWith(pdfReaderServerAddr)) return;
        webView.current?.stopLoading();
        Linking.openURL(e.url);
      }}
    />
  </View> : <Loading p={loadingProgress} />;
};

export default PdfWebViewer;
