import {pick} from 'lodash';
import * as PF from 'paper-fetch';
import {getPaperQuery, SourceKey, SourcePaper} from 'paper-fetch';

import {md5} from '../platform/misc';
import {SortType} from './store';
require('format-unicorn');

/**
 * replace any sequence of whitespace with a single space
 * @param title - title
 * @returns normalized title
 */
export function normalizeTitle(title: string): string {
  return title.replace(/\s+/g, ' ');
}

export type PaperOutline = {
  dest?: string;
  title: string;
  items?: PaperOutline[];
};

export type Author = {
  fullName: string;
};

type PdfHighlightRect = {
  pageNumber?: number,
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  height: number,
  width: number,
}

export type PdfHighlight = {
  id: string,
  content?: {
    text?: string,
    image?: string,
    canEdit?: boolean,
  },
  position?: {
    id?: string,
    description?: string,
    boundingRect?: PdfHighlightRect,
    pageNumber?: number,
    rects?: PdfHighlightRect[],
    paragraphId?: string,
    sectionId?: string,
    sectionTitle?: string,
  },
  comment?: {
    text: string,
    emoji: string,
  },
}

export type Destination = {
  name: string;
  // paper: SimplePaper;
  page: number;
  x: number;
  y: number;
  z: number;
  textStart: number;
  text: string;
};

export type Annotation = {
  id: string;
  dest: string;
  rect: number[];
}

export type SearchMatch = {
  indices: [number, number][];
  text?: string;
}

/**
 * Paper type
 */
type Paper = PF.Paper & {
  id: string;
  /**
   * a short abbreviation of the paper
   * (e.g. name of the main proposed method)
  */
  customAlias?: string;
  pdfUrl?: string;
  htmlUrl?: string;
  abstract?: string;
  /** a paper summary in few sentences */
  tldr?: string;

  affiliations: string[];
  keywords?: string[];

  tags: string[];
  /** tags manually added by users */
  customTags: string[];

  /** a record of results retured by each metadata source */
  sources: Record<SourceKey, SourcePaper>;

  dateAdded: number;
  dateModified: number;
  dateFetched: Record<string, number>;
  dateOpened?: number;

  pdfInfo?: {
    currentPage?: number;
    numPages?: number;
    width?: number;
    height?: number;
    outline?: PaperOutline[];
    destinations?: Record<string, Destination>;
    // annotations: Annotation[][];
  };
  notes: {
    dateAdded?: number;
    dateModified?: number;
    content: string;
  }[];
  urls: {
    url: string;
    desc: string;
  }[];
  zoom: number;
  channels: string[];
  pdfHighlights: PdfHighlight[];
  pdfDisplayHorizontal: boolean;
  availableOffline: boolean;
  pdfPath?: string;
  viewer: 'pdf' | 'html' | 'html-native';

  /** app state fields */
  size?: number;
  matches?: {
    abstract?: SearchMatch;
    title?: SearchMatch;
    authorFull?: SearchMatch;
    venueShort?: SearchMatch;
    venue?: SearchMatch;
    alias?: SearchMatch;
    customAlias?: SearchMatch;
    year?: SearchMatch;
  };

  /** generated fields */
  authorShort?: string;
  authorFull?: string;
  authorFullLastName?: string;
  authorNames?: string[];
  note: string;
}

export type PartialPaper = Partial<Paper> & {
  id: string;
  title: string;
  tags: string[];
}

export const paperSearchKeys = [
  'title', 'abstract', 'authorFull', 'venueShort', 'venue', 'alias',
  'customAlias', 'year'];

/**
 * Create a new paper with default fields
 * @param id - paper id
 * Paper object with default values
 */
export function createNewPaper(id?: string, pfPaper?: PF.Paper): Paper {
  return {
    id: id || '',
    tags: [],
    customTags: [],
    notes: [],
    channels: [],
    note: '',
    pdfHighlights: [],
    pdfDisplayHorizontal: false,
    availableOffline: false,
    zoom: 100,
    viewer: 'pdf',
    dateAdded: Date.now(),
    dateModified: Date.now(),
    ...(pfPaper || PF.createEmptyPaper()),
  } as Paper;
}

export const AuthorSchema = {
  name: 'Author',
  properties: {
    id: 'string',
    fullName: 'string',
  },
};

/**
 * @returns minimum JSON string of the paper for serialization.
 */
export function getPaperLocalJSON(p: Paper): Record<string, unknown> {
  return pick(p, [
    'title',
    'abstract',
    'venue',
    'venueShort',
    'year',
    'alias',
    'customAlias',
    'pdfUrl',
    'htmlUrl',
    'authors',
    'removed',
    'thumbnail',
    'dateAdded',
    'dateModified',
    'dateFetched',
    'dateOpened',
    'urls',
    'currentPage',
    'affiliations',
    'numCitations',
    'numReferences',
    'autoTags',
    'customTags',
    'syncRequired',
    'tldr',
    'note',
    'pdfHighlights',
    'pdfDisplayHorizontal',
    'pdfPath',
    'availableOffline',
    'pdfInfo',
    'textContent',
    'viewer',
    'zoom',
  ]);
}

/**
 * @returns minimum JSON string of the paper for serialization.
 */
export function getPaperRemoteJSON(p: Paper): Record<string, unknown> {
  return pick(p, [
    'ids',
    'title',
    'alias',
    'customAlias',
    'pdfUrl',
    'htmlUrl',
    'authors',
    'autoTags',
    'customTags',
    'removed',
    'thumbnail',
    'dateAdded',
    'dateModified',
    'dateOpened',
    'currentPage',
    'numCitations',
    'year',
    'venue',
    'venueShort',
    'note',
    'pdfHighlights',
    'pdfDisplayHorizontal',
    'availableOffline',
  ]);
}

/**
 * @param p - paper object
 * @returns remote public JSON used to update the paper dataset
 */
export function getPaperRemotePublicJSON(p: Paper): Record<string, unknown> {
  return pick(p, [
    'id',
    'ids',
    'alias',
    'title',
    'abstract',
    'tldr',
    'authors',
    'autoTags',
    'numCitations',
    'numReferences',
    'year',
    'venue',
    'venueShort',
    'dateFetched',
    'pdfUrl',
    'htmlUrl',
  ]);
}

/**
 * Normalize & remove duplicate tags.
 */
function _refreshTags(p: Paper): Paper {
  return {
    ...p,
    tags: [
      ...new Set(
          [...p.autoTags, ...p.customTags]
              .map((s) => s?.toLowerCase().replace(/\s+/, '-'))
              .filter((s) => s),
      ),
    ],
  };
}

/**
 * Refresh authors fields.
 */
function _refreshAuthors(p: Paper): Paper {
  const authorNames = p.authors.map((a) => a.fullName || '');
  return {
    ...p,
    authorNames,
    authorShort: authorNames.length > 2 ? `${authorNames[0]
        .split(' ')
        .slice(-1)
        .pop()} et al.` : authorNames.join(', '),
    authorFull: authorNames.join(', '),
    authorFullLastName: authorNames
        .map((name) => name.split(' ').slice(-1).pop())
        .join(', '),
  };
}

/**
 * Refresh urls.
 */
function _refreshUrls(p: Paper): Paper {
  let urls = p.urls.filter((u) => u.url);
  urls = urls.filter(({url}, index, self) =>
    index === self.findIndex((t) => t.url === url));
  return {...p, urls};
}

/**
 * Called after changing paper's fields to clean if necessary and
 * update other dependent fields.
 */
export function refreshPaper(p: Paper): Paper {
  let paper = {
    ...p,
    ...PF.refreshPaper(p),
    ids: {
      ...p.ids,
      paperShelf: p.id,
    },
  } as Paper;
  paper = _refreshTags(paper);
  paper = _refreshAuthors(paper);
  paper = _refreshUrls(paper);

  // others
  paper.year = paper.year?.toString();

  return paper;
}

/**
 * Generate paper's id based on title and authors
 * @returns paper's id
 */
export async function getPaperId(p: Paper | PF.Paper) {
  if (!p.title || !p.authors || p.authors.length === 0) {
    throw Error('Missing title or authors' + JSON.stringify(p));
  }
  const newId =
    p.title +
    p.authors
        ?.map((a) => a.fullName.split(' ').slice(-1).pop())
        .sort()
        .join();
  return await md5(newId.toLowerCase().replace(/\W/g, ''));
}

/**
 * Convert key-value pairs to Paper object
 * @param papers - list of papers of key-value pairs
 * @returns list of papers
 */
export function getPapersFromObject(papers: Record<string, unknown>): Paper[] {
  return Object.entries(papers).map(
      ([key, paper]) =>
        ({
          ...(paper as Paper),
          id: key,
        }),
  );
}

/**
 * @param paper - a paper
 * @param fetchPaperSources - list of source names
 * @returns boolean indicating whether the paper should be fetched
 */
export function shouldFetch(
    paper: Paper, fetchPaperSources: SourceKey[]) {
  const sources = fetchPaperSources.filter((s) => !paper.sources[s]);
  return sources.length > 0;
}

/**
 * Fetch paper details from different sources
 * @param paper - a paper
 * @param fetchPaperSources - list of source names
 */
export async function fetchPaper(
    paper: Paper,
    fetchPaperSources: SourceKey[],
    forceRefresh?: boolean,
    updateProgressFn?: (paper: Paper, msg: string) => void,
): Promise<Paper> {
  let p: Paper = {...paper};
  const sources = fetchPaperSources.filter(
      (s) => forceRefresh || !p.sources[s]);
  if (sources.length > 0) {
    const pfp = await PF.fetchPaper(
        getPaperQuery(paper), sources,
        updateProgressFn && ((pfp: PF.Paper, msg: string) =>
          updateProgressFn({...paper, ...pfp}, msg)));
    p = {
      ...p,
      ...pfp,
      sources: {...p.sources, ...pfp.sources},
      dateModified: Date.now(),
    };
  }
  return p;
}

/**
 * search for papers
 * @param query - a string query
 * @param searchPaperSources - list of source names
 * @param callback - callback function called after each source finished
 * @param offset - offset value
 * @param limit - limit value
 * @returns list of papers
 */
export async function searchPaper(
    query: string,
    searchPaperSources: SourceKey[],
    callback?: (p: Paper[], source: string) => void,
    offset = 0,
    limit = 10,
): Promise<Paper[]> {
  try {
    const pfPapers = await PF.searchPaper(
        query, searchPaperSources,
        callback && (async (pfps: PF.Paper[], msg: string) => {
          callback(await Promise.all(
              pfps.map(async (pfp) =>
                refreshPaper({
                  ...createNewPaper(await getPaperId(pfp), pfp),
                }))), msg);
        }),
        offset, limit);
    const papers = await Promise.all(pfPapers.map(async (pfp) => refreshPaper({
      ...createNewPaper(),
      ...pfp,
      id: await getPaperId(pfp),
      dateModified: Date.now(),
    } as Paper)));
    return papers;
  } catch (e) {
    return [];
  }
}

/**
 * Sort a list of papers
 * @param paperList - list of papers
 * @param sortType - one of SortType
 * @returns sorted list of papers
 */
export function sortPapers(paperList: PartialPaper[], sortType: SortType) {
  switch (sortType) {
    case SortType.ByDateOpened:
      return paperList.sort((a, b) =>
        -(a.dateOpened || 0) + (b.dateOpened || 0),
      );
    case SortType.ByDateAdded:
      return paperList.sort((a, b) =>
        a.dateAdded && b.dateAdded ? -a.dateAdded + b.dateAdded : 1,
      );
    case SortType.ByDateModified:
      return paperList.sort((a, b) =>
        a.dateModified && b.dateModified ? -a.dateModified + b.dateModified : 1,
      );
    case SortType.ByYear:
      return paperList.sort((a, b) =>
        a.year && b.year ? -(a.year || '').localeCompare(b.year || '') : 1,
      );
    case SortType.ByCitation:
      return paperList.sort((a, b) =>
        -(a.numCitations || 0) + (b.numCitations || 0),
      );
    case SortType.ByTitle:
      return paperList.sort((a, b) =>
        a.title && b.title ? a.title.localeCompare(b.title) : 1,
      );
    default:
      return paperList;
  }
}

/**
 * Merge two papers
 * @param p1 - paper 1
 * @param p2 - paper 2
 * @returns - merged paper
 */
export function mergePaper(p1: Paper | null, p2: Paper | null): Paper {
  return {
    ...createNewPaper(),
    ...p1 || {},
    ...p2 || {},
    sources: {
      ...p1?.sources,
      ...p2?.sources,
    },
  } as Paper;
}

/**
 * get reference papers
 * @param p - paper
 */
export async function getReferencePapers(p: Paper): Promise<Paper[]> {
  const pfPapers = await PF.getReferencePapers(p);
  return await Promise.all(pfPapers.map(async (pfp) => refreshPaper({
    ...createNewPaper(),
    ...pfp,
    id: await getPaperId(pfp),
  } as Paper)));
}

/**
 * get citation papers
 * @param p - paper
 */
export async function getCitationPapers(p: Paper): Promise<Paper[]> {
  const pfPapers = await PF.getCitationPapers(p);
  return await Promise.all(pfPapers.map(async (pfp) => refreshPaper({
    ...createNewPaper(),
    ...pfp,
    id: await getPaperId(pfp),
  } as Paper)));
}

export default Paper;

