import {Text, useTheme} from '@rneui/themed';
import React from 'react';
import {
  ActivityIndicator, View,
} from 'react-native';

import {IconProps} from '../platform/icons';

const _ActivityIndicator = ({testID, size, text, center}: IconProps & {
  text?: string;
  center?: boolean;
}) => {
  const {theme} = useTheme();
  return center ? <View style={{
    position: 'absolute',
    left: 0, right: 0, bottom: 0, top: 0,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
  }}>
    <ActivityIndicator
      size={size}
      testID={testID}
      color={theme.colors.black} />
    <Text style={{marginTop: 8}}>{text}</Text>
  </View> : <ActivityIndicator
    size={size}
    testID={testID}
    color={theme.colors.black} />;
};

export default _ActivityIndicator;
