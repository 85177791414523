import Clipboard from '@react-native-clipboard/clipboard';
import {CheckBox, ListItem, Text, useTheme} from '@rneui/themed';
import React, {useContext, useState} from 'react';
import {Pressable, View} from 'react-native';
import {useRecoilValue} from 'recoil';

import {NavigationContext} from '../../context';
import {dialog} from '../../platform/dialog';
import {IconProps} from '../../platform/icons';
import {toastSuccess} from '../../platform/toast';
import {isWebState} from '../../recoil/selectors';
import {Prompt} from '../Prompt';

export enum ItemKind {
  List,
  Menu,
  Separator,
  Section,
  CheckBox,
  Input,
  Button,
  Text,
  Custom,
}

export type PreferenceItemProps = {
  id: string;
  testID?: string;
  kind: ItemKind;
  title?: string | JSX.Element;
  icon?: (props: IconProps) => JSX.Element;
  subTitle?: string | JSX.Element;
  onPress?: () => void;
  onChange?: (value?: string | boolean) => void;
  refresh?: () => void;
  value?: boolean | string | null;
  disabled?: boolean;
  pressable?: boolean;
  hidden?: boolean;
  devOnly?: boolean;
  adminOnly?: boolean;
  readonly?: boolean;
  inline?: boolean;
  numberOfLines?: number;
  showValueOnPress?: boolean;
  component?: JSX.Element;
  separator?: boolean;
  firstItem?: boolean;
  lastItem?: boolean;
  padItem?: boolean;
  color?: string;
  clickToCopy?: boolean | string;
  subItems?: () => PreferenceItemProps[];
  indent?: boolean;
  leftButton?: {
    icon: (props: IconProps) => JSX.Element;
    onPress: () => void;
  };
  defaultShowSubItems?: boolean;
};

export type PreferenceSectionProps = {
  id: string;
  title?: string;
  items: () => PreferenceItemProps[];
  subTitle?: string;
  hidden?: boolean;
  adminOnly?: boolean;
};

export const PreferenceItem = ({
  id,
  testID,
  title,
  subTitle,
  disabled,
  icon,
  kind,
  onPress,
  onChange,
  value,
  refresh,
  readonly,
  pressable = true,
  inline,
  numberOfLines,
  showValueOnPress,
  component,
  devOnly,
  adminOnly,
  separator = true,
  firstItem = false,
  lastItem = false,
  padItem = true,
  color,
  clickToCopy = false,
  subItems,
  indent = false,
  leftButton,
  defaultShowSubItems = false,
}: PreferenceItemProps): JSX.Element => {
  const {theme} = useTheme();
  const [_editMode, setEditMode] = useState<boolean>(false);
  const {showModal} = useContext(NavigationContext);
  const isWeb = useRecoilValue(isWebState);
  const [showSubItems, setShowSubItems] =
    useState<boolean>(defaultShowSubItems);

  const _onPress = () => {
    if (disabled) return;
    if (!pressable) return;
    if (kind === ItemKind.List) {
      setShowSubItems(!showSubItems);
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    } else if (kind === ItemKind.Input && !readonly) {
      // Show prompt
      showModal((close) =>
        <Prompt
          title={title as string || ''}
          subTitle={subTitle as string || ''} /* TODO: support component */
          initialValue={value as string}
          onSubmit={(val) => {
            onChange && onChange(val);
            close();
          }}
          onCancel={close} />, {
        title: '',
        maxWidth: 400,
      });
    } else if (kind == ItemKind.Text) {
      if (showValueOnPress) {
        dialog(typeof title === 'string' ? title : '', transformValue(value));
      } else if (clickToCopy) {
        const stringToCopy = typeof clickToCopy === 'string' ? clickToCopy :
          (subTitle || value) as string;
        if (!stringToCopy || typeof stringToCopy !== 'string') return;
        Clipboard.setString(stringToCopy);
        toastSuccess('Copied');
      }
    }
    if (onPress) onPress();
    if (onChange && kind === ItemKind.CheckBox) onChange(!value);
    if (refresh) refresh();
  };

  const transformValue = (value: unknown) => {
    if (typeof value === 'string') return value;
    if (typeof value === 'boolean') return value ? 'yes' : 'no';
  };

  const getSubTitle = () => {
    if (kind === ItemKind.Input) return transformValue(value) || 'N/A';
    if (subTitle) return subTitle;
    if (kind === ItemKind.CheckBox) return null;
    return null;
  };

  const getContent = (highlight: boolean) => {
    const _color = adminOnly ? theme.colors.warning :
        (color || (disabled ? theme.colors?.grey4 : theme.colors?.black));
    if (kind === ItemKind.Custom) {
      return component;
    } else if (kind == ItemKind.Separator) {
      return <></>;
    } else {
      return (
        <>
          {icon && <View style={{width: 16, marginRight: 16}}>
            {icon({color: _color})}
          </View>}
          <ListItem.Content
            style={{
              flexDirection: inline ? 'row' : 'column',
              justifyContent: 'flex-start',
            }}
          >
            {__DEV__ && devOnly && <Text style={{fontSize: 8}}>DEV</Text>}

            {typeof title === 'string' ? (<ListItem.Title
              style={{
                flex: inline ? undefined : 1,
                textAlign: 'left',
                paddingRight: 32,
                color: _color,
              }}
              numberOfLines={numberOfLines || 1}
              testID={`pref_item_title_${id}`}
            >
              {title}
            </ListItem.Title>) : (title)}

            {getSubTitle() && (
              <ListItem.Subtitle
                style={{
                  color: disabled ? theme.colors?.grey4 : theme.colors?.grey2,
                  flex: 1,
                  flexDirection: 'row',
                  textAlign: inline ? 'right' : 'left',
                }}
                testID={`pref_item_subtitle_${id}`}
                numberOfLines={numberOfLines || 1}
                onPress={(isWeb && kind === ItemKind.Input) ? () => {
                  setEditMode(true);
                } : undefined}
              >
                {getSubTitle()}
                {/* {editMode && kind === ItemKind.Input && isWeb && (
                  <Input
                    containerStyle={{
                      flex: 1,
                      margin: 0,
                      padding: 0,
                      height: 19,
                    }}
                    inputStyle={{
                      fontSize: 14,
                      paddingHorizontal: 8,
                      paddingVertical: 0,
                      margin: 0,
                      textAlign: 'right',
                    }}
                    style={{}}
                    inputContainerStyle={{height: 19}}
                    selectTextOnFocus={true}
                    value={transformValue(value)}
                    onChangeText={(text) => {
                      if (onChange) onChange(text);
                    }}
                  />
                )} */}
              </ListItem.Subtitle>
            )}
          </ListItem.Content>
          {kind === ItemKind.CheckBox && (
            <CheckBox
              right
              iconRight
              containerStyle={{
                margin: 0, padding: 0,
                height: 20,
                backgroundColor: highlight ? theme.colors?.backgroundHighlight :
              theme.colors?.white,
              }}
              checked={value as boolean}
              onPress={(_e) => _onPress()}
              disabled={disabled}
            />
          )}
          {kind === ItemKind.Menu && <ListItem.Chevron />}
          {kind == ItemKind.Section && <ListItem.Accordion />}
          {leftButton && <Pressable
            style={{
              backgroundColor: theme.colors?.white,
              marginVertical: -20,
              marginRight: -8,
              width: 48,
              height: 48,
              justifyContent: 'center'}}
            onPress={leftButton.onPress}>
            {() => leftButton.icon({})}
          </Pressable>}
        </>
      );
    }
  };

  const listItem = <Pressable
    // onPress={isWeb ? _onPress : undefined}
    // onPressIn={isPad ? _onPress : undefined}
    onPress={_onPress}
    testID="pref_item"
    disabled={disabled}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    style={{flex: 1, cursor: disabled || !pressable ? 'default' : 'pointer'}}
  >
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    {({pressed, hovered}) => <ListItem
      disabled={disabled}
      testID={testID || `pref_item_${id}`}
      containerStyle={{
        padding: kind === ItemKind.Custom ? 0 : 16,
        paddingLeft: indent ? 32 : undefined,
        backgroundColor: pressable ?
          (pressed ? theme.colors?.backgroundPressed : (
            hovered ? theme.colors?.backgroundHovered : theme.colors.white
          )) : theme.colors.white,
        borderTopLeftRadius: firstItem && padItem ? 12 : 0,
        borderTopRightRadius: firstItem && padItem ? 12 : 0,
        borderBottomLeftRadius: lastItem && padItem ? 12 : 0,
        borderBottomRightRadius: lastItem && padItem ? 12 : 0,
      }}
    >
      {getContent(pressed && pressable)}
    </ListItem>}
  </Pressable>;

  return <View style={{
    marginHorizontal: padItem ? 16 : 0,
    borderColor: theme.colors.border,
    borderBottomWidth: separator ? 1 : 0,
    borderLeftWidth: padItem ? 1 : 0,
    borderRightWidth: padItem ? 1 : 0,
    borderTopColor: theme.colors.border,
    borderTopWidth: firstItem ? 1 : 0,
    borderTopLeftRadius: firstItem && padItem ? 12 : 0,
    borderTopRightRadius: firstItem && padItem ? 12 : 0,
    borderBottomLeftRadius: lastItem && padItem ? 12 : 0,
    borderBottomRightRadius: lastItem && padItem ? 12 : 0,
  }}>
    {(kind === ItemKind.List && subItems) ? (
      <ListItem.Accordion
        containerStyle={{
          flex: 1,
          padding: 0,
          paddingRight: 16,
          backgroundColor: theme.colors.white,
        }}
        content={listItem}
        isExpanded={showSubItems}
        onPress={() => {
          setShowSubItems(!showSubItems);
        }}>
        {subItems && showSubItems && subItems().map((item, index) => (
          <PreferenceItem
            {...item}
            key={item.id} padItem={false}
            indent={true}
            firstItem={index === 0}
            lastItem={index === subItems().length - 1}
            separator={index !== subItems().length - 1}
          />))}
      </ListItem.Accordion>
    ) : listItem}
  </View>;
};
