import {Icon} from '@rneui/themed';
import React from 'react';
import {ColorValue} from 'react-native';

export type IconProps = {
  size?: number;
  color?: number | ColorValue;
  testID?: string;
  // style?: unknown; // TODO: find style typescript def
};

export const IconStar = (props: IconProps): JSX.Element => (
  <Icon name="star" type="antdesign" {...props} />
);

export const IconNoStar = (props: IconProps): JSX.Element => (
  <Icon name="staro" type="antdesign" size={16} {...props} />
);

export const IconHidden = (props: IconProps): JSX.Element => (
  <Icon name="eye-off" type="ionicon" size={16} {...props} />
);

export const IconCloud = (): JSX.Element => (
  <Icon name="sc-telegram" type="evilicon" color="#517fa4" />
);

export const IconTimer = (props: IconProps): JSX.Element => (
  <Icon name="ios-timer" type="ionicon" size={16} {...props} />
);

export const IconPdf = (props: IconProps): JSX.Element => (
  <Icon name="pdffile1" type="antdesign" size={16} {...props} />
);

export const IconHtml = (props: IconProps): JSX.Element => (
  <Icon name="filetext1" type="antdesign" size={16} {...props} />
);

export const IconGoogle = (props: IconProps): JSX.Element => (
  <Icon name="google" type="antdesign" size={16} {...props} />
);

export const IconApple = (props: IconProps): JSX.Element => (
  <Icon name="apple1" type="antdesign" size={16} {...props} />
);

export const IconEmail = (props: IconProps): JSX.Element => (
  <Icon name="email" type="material" size={16} {...props} />
);

export const IconDelete = (props: IconProps): JSX.Element => (
  <Icon name="delete" type="antdesign" size={16} {...props} />
);

export const IconRemoveFile = (props: IconProps): JSX.Element => (
  <Icon
    name="note-remove-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconUpload = (props: IconProps): JSX.Element => (
  <Icon name="upload" type="font-awesome" size={16} {...props} />
);

export const IconAdd = (props: IconProps): JSX.Element => (
  <Icon name="plus" type="font-awesome" size={16} {...props} />
);

export const IconRemoveCollection = (props: IconProps): JSX.Element => (
  <Icon name="folder-remove" type="material-community" size={16} {...props} />
);

export const IconAddCollection = (props: IconProps): JSX.Element => (
  <Icon
    name="folder-plus-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconManageCollection = (props: IconProps): JSX.Element => (
  <Icon
    name="folder-cog-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconPublicCollection = (props: IconProps): JSX.Element => (
  <Icon name="appstore-o" type="ant-design" size={16} {...props} />
);

export const IconAddLibrary = (props: IconProps): JSX.Element => (
  <Icon name="library-add" type="material" size={16} {...props} />
);

export const IconCitation = (props: IconProps): JSX.Element => (
  <Icon name="quote-left" type="font-awesome" size={16} {...props} />
);

export const IconReference = (props: IconProps): JSX.Element => (
  <Icon name="external-link" type="font-awesome" size={16} {...props} />
);

export const IconHome = (props: IconProps): JSX.Element => (
  <Icon name="home-outline" type="ionicon" size={16} {...props} />
);

export const IconConfig = (props: IconProps): JSX.Element => (
  <Icon name="settings-outline" type="ionicon" size={16} {...props} />
);

export const IconRotate = (props: IconProps): JSX.Element => (
  <Icon
    name="phone-rotate-landscape"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconNote = (props: IconProps): JSX.Element => (
  <Icon name="note-outline" type="material-community" size={16} {...props} />
);

export const IconNoteAdd = (props: IconProps): JSX.Element => (
  <Icon
    name="note-plus-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconNoteOff = (props: IconProps): JSX.Element => (
  <Icon
    name="note-off-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconComment = (props: IconProps): JSX.Element => (
  <Icon name="comment-alt" type="font-awesome-5" size={16} {...props} />
);

export const IconHighlight = (props: IconProps): JSX.Element => (
  <Icon name="highlighter" type="font-awesome-5" size={16} {...props} />
);

export const IconUp = (props: IconProps): JSX.Element => (
  <Icon name="settings" type="ionicon" size={16} {...props} />
);

export const IconCheck = (props: IconProps): JSX.Element => (
  <Icon name="check" type="font-awesome" size={16} {...props} />
);

export const IconChecked = (props: IconProps): JSX.Element => (
  <Icon name="checkbox-active" type="fontisto" size={16} {...props} />
);

export const IconUnchecked = (props: IconProps): JSX.Element => (
  <Icon name="checkbox-passive" type="fontisto" size={16} {...props} />
);

export const IconDown = (props: IconProps): JSX.Element => (
  <Icon name="settings" type="ionicon" size={16} {...props} />
);

export const IconBack = (props: IconProps): JSX.Element => (
  <Icon name="arrow-back" type="ionicon" size={16} {...props} />
);

export const IconBookmark = (props: IconProps): JSX.Element => (
  <Icon name="home" type="font-awesome" {...props} />
);

export const IconHide = (props: IconProps): JSX.Element => (
  <Icon name="home" type="font-awesome" {...props} />
);

export const IconBookshelf = (props: IconProps): JSX.Element => (
  <Icon name="library-outline" type="ionicon" size={16} {...props} />
);

export const IconDetails = (props: IconProps): JSX.Element => (
  <Icon name="ellipsis-v" type="font-awesome" size={16} {...props} />
);

export const IconMore = (props: IconProps): JSX.Element => (
  <Icon name="ellipsis-v" type="font-awesome" size={16} {...props} />
);

export const IconMenu = (props: IconProps): JSX.Element => (
  <Icon name="menu" type="material" size={16} {...props} />
);

export const IconExpand = (props: IconProps): JSX.Element => (
  <Icon name="window-maximize" type="font-awesome" size={16} {...props} />
);

export const IconSyncOn = (props: IconProps): JSX.Element => (
  <Icon name="sync-circle" type="ionicon" size={16} {...props} />
);

export const IconLogin = (props: IconProps): JSX.Element => (
  <Icon name="login" type="material" size={16} {...props} />
);

export const IconLogout = (props: IconProps): JSX.Element => (
  <Icon name="logout" type="material" size={16} {...props} />
);

export const IconMinimize = (props: IconProps): JSX.Element => (
  <Icon name="window-minimize" type="font-awesome" size={16} {...props} />
);

export const IconClose = (props: IconProps): JSX.Element => (
  <Icon name="close" type="font-awesome" size={16} {...props} />
);

export const IconCollapseLeft = (props: IconProps): JSX.Element => (
  <Icon
    name="arrow-collapse-left"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconCollapseRight = (props: IconProps): JSX.Element => (
  <Icon
    name="arrow-collapse-right"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconSort = (props: IconProps): JSX.Element => (
  <Icon name="sort-alpha-asc" type="font-awesome" size={16} {...props} />
);

export const IconSearch = (props: IconProps): JSX.Element => (
  <Icon name="search" type="font-awesome" size={16} {...props} />
);

export const IconSearchWeb = (props: IconProps): JSX.Element => (
  <Icon name="search-web" type="material-community" size={16} {...props} />
);

export const IconPaper = (props: IconProps): JSX.Element => (
  <Icon name="file-o" type="font-awesome" size={16} {...props} />
);

export const IconDownload = (props: IconProps): JSX.Element => (
  <Icon name="save-alt" type="material" size={16} {...props} />
);

export const IconOffline = (props: IconProps): JSX.Element => (
  <Icon name="save-alt" type="material" size={16} {...props} />
);

export const IconPapers = (props: IconProps): JSX.Element => (
  <Icon name="files-o" type="font-awesome" size={16} {...props} />
);

export const IconSync = (props: IconProps): JSX.Element => (
  <Icon
    name="cloud-sync-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconNews = (props: IconProps): JSX.Element => (
  <Icon name="newspaper-o" type="font-awesome" size={16} {...props} />
);

export const IconCollection = (props: IconProps): JSX.Element => (
  <Icon name="folder-outline" type="material-community" size={16} {...props} />
);

export const IconEditCollection = (props: IconProps): JSX.Element => (
  <Icon
    name="folder-edit-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconAccount = (props: IconProps): JSX.Element => (
  <Icon name="user-o" type="font-awesome" size={16} {...props} />
);

export const IconTag = (props: IconProps): JSX.Element => (
  <Icon name="tag-outline" type="material-community" size={16} {...props} />
);

export const IconNewTag = (props: IconProps): JSX.Element => (
  <Icon
    name="tag-plus-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconAlias = (props: IconProps): JSX.Element => (
  <Icon
    name="format-letter-matches"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconRefresh = (props: IconProps): JSX.Element => (
  <Icon name="refresh" type="font-awesome" size={16} {...props} />
);

export const IconClear = (props: IconProps): JSX.Element => (
  <Icon name="layers-clear" type="material" size={16} {...props} />
);

export const IconEdit = (props: IconProps): JSX.Element => (
  <Icon name="edit" type="font-awesome-5" size={16} {...props} />
);

export const IconInfo = (props: IconProps): JSX.Element => (
  <Icon
    name="card-text-outline"
    type="material-community"
    size={16}
    {...props}
  />
);

export const IconOpenUrl = (props: IconProps): JSX.Element => (
  <Icon name="open-in-browser" type="material" size={16} {...props} />
);

export const IconGlobal = (props: IconProps): JSX.Element => (
  <Icon name="globe" type="font-awesome" size={16} {...props} />
);

export const IconShare = (props: IconProps): JSX.Element => (
  <Icon name="share-social-outline" type="ionicon" size={16} {...props} />
);

export const IconShareFile = (props: IconProps): JSX.Element => (
  <Icon name="share-outline" type="ionicon" size={16} {...props} />
);

export const IconSuccess = (props: IconProps): JSX.Element => (
  <Icon name="checkcircle" type="ant-design" size={16} {...props} />
);

export const IconError = (props: IconProps): JSX.Element => (
  <Icon name="closecircle" type="ant-design" size={16} {...props} />
);

export const IconViewPaper = (props: IconProps): JSX.Element => (
  <Icon name="open-outline" type="ionicon" size={16} {...props} />
);

export const IconEye = (props: IconProps): JSX.Element => (
  <Icon name="eye" type="entypo" size={16} {...props} />
);

export const IconEyeOff = (props: IconProps): JSX.Element => (
  <Icon name="eye-with-line" type="entypo" size={16} {...props} />
);

export const IconStyle = (props: IconProps): JSX.Element => (
  <Icon name="color-fill-outline" type="ionicon" size={16} {...props} />
);

export const IconPriority = (props: IconProps): JSX.Element => (
  <Icon name="sort-numeric-asc" type="font-awesome" size={16} {...props} />
);

export const IconCollapsed = (props: IconProps): JSX.Element => (
  <Icon name="caret-right" type="font-awesome" size={16} {...props} />
);

export const IconExpanded = (props: IconProps): JSX.Element => (
  <Icon name="caret-down" type="font-awesome" size={16} {...props} />
);

export const IconWarning = (props: IconProps): JSX.Element => (
  <Icon name="warning" type="ant-design" size={16} {...props} />
);

export const IconChat = (props: IconProps): JSX.Element => (
  <Icon name="chatbox-outline" type="ionicon" size={16} {...props} />
);

export const IconHelp = (props: IconProps): JSX.Element => (
  <Icon name="question" type="octicon" size={16} {...props} />
);

export const IconCaretDown = (props: IconProps): JSX.Element => (
  <Icon name="caretdown" type="ant-design" size={16} {...props} />
);

export const IconSwitch = (props: IconProps): JSX.Element => (
  <Icon name="arrow-switch" type="octicon" size={16} {...props} />
);

export const IconZoomIn = (props: IconProps): JSX.Element => (
  <Icon name="zoom-in" type="feather" size={16} {...props} />
);

export const IconZoomOut = (props: IconProps): JSX.Element => (
  <Icon name="zoom-out" type="feather" size={16} {...props} />
);

export const IconText = (props: IconProps): JSX.Element => (
  <Icon name="text" type="ionicon" size={16} {...props} />
);

export const IconOptions = (props: IconProps): JSX.Element => (
  <Icon name="options-outline" type="ionicon" size={16} {...props} />
);

export const IconArrows = (props: IconProps): JSX.Element => (
  <Icon name="arrows" type="font-awesome" size={16} {...props} />
);

export const iconNames = [
  'star',
  'favorite',
  'read',
  'unread',
  'bookmark',
].sort();

/**
 * @param name - name
 * @returns icon corresponding to the name
 */
export function getIconByName(
    name: string,
): ((props: IconProps) => JSX.Element) | undefined {
  switch (name) {
    case 'star':
      return IconStar;
    case 'favorite':
      // eslint-disable-next-line react/display-name
      return (props: IconProps) => (
        <Icon name="heart" type="ant-design" {...props} />
      );
    case 'bookmark':
      // eslint-disable-next-line react/display-name
      return (props: IconProps) => (
        <Icon name="favorite" type="fontisto" {...props} />
      );
    case 'read':
      // eslint-disable-next-line react/display-name
      return (props: IconProps) => (
        <Icon name="checkbox-active" type="fontisto" {...props} />
      );
    case 'unread':
      // eslint-disable-next-line react/display-name
      return (props: IconProps) => (
        <Icon name="checkbox-passive" type="fontisto" {...props} />
      );
    default:
      return undefined;
  }
}
