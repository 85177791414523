import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {NavigationProp, useNavigation} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {Button, Text} from '@rneui/themed';
import {useTheme} from '@rneui/themed';
import * as AppleAuthentication from 'expo-apple-authentication';
import {ResponseType} from 'expo-auth-session';
import * as Facebook from 'expo-auth-session/providers/facebook';
import * as Google from 'expo-auth-session/providers/google';
import * as Crypto from 'expo-crypto';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  KeyboardAvoidingView,
  LayoutAnimation,
  Linking,
  TextInput,
  TextInputProps,
  View,
} from 'react-native';
import {useRecoilState, useRecoilValue} from 'recoil';

import Api, {UserInfo} from '../../common/api';
import {isLoggedIn} from '../../common/api/utils';
import {User} from '../../common/store';
import {ItemKind, PreferencesScreen} from '../../components/Preferences';
import {Prompt} from '../../components/Prompt';
import {AppContext, NavigationContext} from '../../context';
import {MainStackParamList} from '../../Main';
import {dialog} from '../../platform/dialog';
import {
  analytics,
  auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from '../../platform/firebase';
import {
  IconApple,
  IconGoogle,
  IconSyncOn,
  IconWarning,
} from '../../platform/icons';
import {toastError, toastSuccess} from '../../platform/toast';
import {settingsState} from '../../recoil/atoms';
import {SettingsStackParamList} from './Navigator';

const GOOGLE_CLIENT_ID = '';
const FACEBOOK_CLIENT_ID = '';

const Input = (props: {
  testID: string;
  placeholder: string;
  value: string;
  onChangeText: (text: string) => void;
  onSubmitEditing: () => void;
} & TextInputProps) => {
  const {theme} = useTheme();
  return <TextInput
    {...props}
    autoCapitalize='none'
    style={{
      marginTop: 16,
      borderWidth: 1,
      padding: 12,
      borderColor: theme.colors?.border,
      color: theme.colors?.black,
      backgroundColor: theme.colors.white,
      marginBottom: 12,
    }}
    underlineColorAndroid="transparent"
    returnKeyType="next"
    blurOnSubmit={false} />;
};

export const LinkedAccounts = (): JSX.Element => {
  const [settings, setSettings] = useRecoilState(settingsState);
  return <PreferencesScreen sections={[{
    id: 'linked-accounts',
    items: () => ([
      {
        id: 'openai',
        title: 'OpenAI',
        kind: ItemKind.Input,
        value: settings.openAIKey ? (settings.openAIKey?.slice(0, 3) + '...' +
            settings.openAIKey?.slice(-3)) : undefined,
        inline: true,
        onChange: (value) => {
          setSettings({...settings, openAIKey: value?.toString()});
        },
      },
    ]),
  }]} />;
};

export const Account = (): JSX.Element => {
  const settings = useRecoilValue(settingsState);

  const [user, setUser] = useState<FirebaseAuthTypes.User | null>(null);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const {loadAppData, clearLocalData} = useContext(AppContext);

  const [, responseGg, promptAsyncGg] = Google.useIdTokenAuthRequest({
    clientId: GOOGLE_CLIENT_ID,
  });

  const [, responseFb, promptAsyncFb] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: FACEBOOK_CLIENT_ID,
  });

  const {theme} = useTheme();
  const navigation =
    useNavigation<StackNavigationProp<SettingsStackParamList>>();

  // Login
  useEffect(() => {
    auth?.currentUser?.reload().then(() => {
      setUser(auth?.currentUser || null);
    });

    const unsubscribe = auth?.onAuthStateChanged(function(u) {
      setUser(u);
    });

    return unsubscribe;
  }, []);

  const refreshUserInfo = async (user: User | null) => {
    await auth?.currentUser?.reload();
    if (user && user.uid) {
      const ui = await Api.Profile.getUserInfo();
      setUserInfo(ui);
    } else {
      setUserInfo(null);
    }
  };

  useEffect(() => {
    refreshUserInfo(user);
  }, [user]);

  React.useEffect(() => {
    if (responseGg?.type === 'success') {
      // eslint-disable-next-line camelcase
      const {id_token} = responseGg.params;

      const credential = GoogleAuthProvider.credential(id_token);
      auth?.signInWithCredential(credential).then(() => {
        toastSuccess('User logged in.');
      });
    }
  }, [responseGg]);

  React.useEffect(() => {
    if (responseFb?.type === 'success') {
      // eslint-disable-next-line camelcase
      const {access_token} = responseFb.params;

      const credential = FacebookAuthProvider.credential(access_token);
      // Sign in with the credential from the Facebook user.
      auth?.signInWithCredential(credential);
    }
  }, [responseFb]);

  /**
   * Log out and ask to clear local data.
   */
  const logout = async () => {
    await auth?.signOut();
    dialog(
        'You have been logged out.',
        'Do you want to clear local data on this device? ' +
        'Data will be restored when you log in again.',
        [
          {
            text: 'Remove',
            onPress: async () => {
              await clearLocalData();
            },
            style: 'destructive',
          },
          {text: 'Keep'},
        ],
    );
  };

  const deleteAccount = async () => {
    dialog(
        'Delete Account',
        'Do you want to permanently delete your account? This will be ' +
        'executed immediately and cannot be undone.',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'Delete Account',
            style: 'destructive',
            onPress: () => {
              Api.Profile.deleteAccount().then(async () => {
                await clearLocalData();
                await refreshUserInfo(user);
                dialog(
                    'Account Deleted',
                    'Your account and data have been deleted.');
              });
            },
          },
        ],
    );
  };

  return (isLoggedIn() && user) ? (
    <PreferencesScreen
      sections={[
        ...((user || userInfo) ?
          [] :
          [
            {
              id: 'login',
              title: '',
              items: () => [
                {
                  id: 'login_with_google',
                  title: 'Login with Google',
                  icon: IconGoogle,
                  kind: ItemKind.Button,
                  onPress: () => {
                    promptAsyncGg().catch(() =>
                      toastError('There was a problem with logging in.'),
                    );
                  },
                },
                {
                  id: 'login_with_facebook',
                  title: 'Login with Facebook',
                  kind: ItemKind.Button,
                  hidden: true,
                  onPress: () => {
                    promptAsyncFb().catch(() =>
                      toastError('There was a problem with logging in.'),
                    );
                  },
                },
                {
                  id: 'login_with_apple',
                  title: 'Login with Apple',
                  kind: ItemKind.Button,
                  icon: IconApple,
                  onPress: () => {
                    const nonce = Math.random().toString(36).substring(2, 10);

                    return Crypto.digestStringAsync(
                        Crypto.CryptoDigestAlgorithm.SHA256,
                        nonce,
                    ).then((hashedNonce) =>
                      AppleAuthentication.signInAsync({
                        requestedScopes: [
                          AppleAuthentication.AppleAuthenticationScope
                              .FULL_NAME,
                          AppleAuthentication.AppleAuthenticationScope.EMAIL,
                        ],
                        nonce: hashedNonce,
                      }),
                    );
                    // .then((appleCredential) => {
                    //   const {identityToken} = appleCredential;
                    //   if (!identityToken) return;
                    //   const provider = new auth.OAuthProvider(
                    //       'apple.com',
                    //   );
                    //   const credential = provider.credential({
                    //     idToken: identityToken,
                    //     rawNonce: nonce,
                    //   });
                    //   return auth?
                    //       .signInWithCredential(credential);
                    //   // Successful sign in is handled by
                    //   // firebase.auth?.onAuthStateChanged
                    // });
                  },
                },
              ],
            },
          ]),
        ...(!user?.emailVerified ? [
          {
            id: 'verification',
            title: '',
            items: () => [
              {
                id: 'verify_email',
                title: 'Resend Verification Email',
                kind: ItemKind.Button,
                icon: IconWarning,
                onPress: async () => {
                  await user.sendEmailVerification();
                  dialog('Email Verification Sent', 'Please check your email.');
                },
              },
            ],
          },
        ] : []),
        ...(user ?
          [
            {
              id: 'profile',
              title: '',
              items: () => [
                {
                  id: 'display_name',
                  kind: ItemKind.Input,
                  title: 'Display Name',
                  inline: true,
                  value: userInfo?.name,
                  onChange: (text: unknown) => {
                    const newUserInfo = {
                      ...userInfo, name: text as string || '',
                    };
                    setUserInfo(newUserInfo);
                    Api.Profile.saveUserInfo(newUserInfo).then(() =>
                      refreshUserInfo(user),
                    );
                  },
                },
                {
                  id: 'email',
                  kind: ItemKind.Input,
                  title: 'Email',
                  inline: true,
                  value: user?.email,
                  readonly: true,
                },
                {
                  id: 'affliation',
                  kind: ItemKind.Input,
                  title: 'Affliation',
                  inline: true,
                  value: userInfo?.affliation,
                  onChange: async (text: unknown) => {
                    if (!userInfo) return;
                    Api.Profile.saveUserInfo({
                      ...userInfo, affliation: text as string});
                    refreshUserInfo(user);
                  },
                },
                {
                  id: 'website',
                  kind: ItemKind.Input,
                  title: 'Website',
                  inline: true,
                  value: userInfo?.website,
                  onChange: async (text: unknown) => {
                    if (!userInfo) return;
                    Api.Profile.saveUserInfo({
                      ...userInfo, website: text as string});
                    refreshUserInfo(user);
                  },
                },
              ],
            },
          ] :
          []),
        {
          id: 'linked_account',
          items: () => [{
            id: 'linked_account',
            kind: ItemKind.Menu,
            title: 'Linked Accounts & API Keys',
            onPress: () => {
              navigation.navigate('LinkedAccount');
            },
          }],
        },
        {
          id: 'others',
          title: '',
          items: () => [
            {
              id: 'sync_now',
              kind: ItemKind.Button,
              title: 'Sync Now...',
              icon: IconSyncOn,
              onPress: () => {
                loadAppData();
              },
              disabled: !settings.sync.enabled,
              devOnly: true,
              hidden: true,
            },
            {
              id: 'logout',
              kind: ItemKind.Button,
              title: 'Logout',
              onPress: logout,
            },
            {
              id: 'change_password',
              kind: ItemKind.Button,
              title: 'Reset Password',
              onPress: () => {
                if (!user?.email) return;
                auth?.sendPasswordResetEmail(user?.email);
                dialog('Password Reset Email Sent', 'Please check your email.');
              },
            },
            {
              id: 'delete_account',
              kind: ItemKind.Button,
              title: 'Delete Account',
              onPress: deleteAccount,
              color: theme.colors.error,
            },
          ],
        },
      ]}
      extraData={user}
    />
  ) : (<LoginScreen />);
};

export const LoginScreen = () => {
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [passwordRetyped, setPasswordRetyped] = React.useState<string>('');
  const [error, setError] = React.useState<string>();
  const {theme} = useTheme();
  const {showModal, closeModal} = useContext(NavigationContext);
  const [mode, setMode] = React.useState<'login' | 'signup'>('login');
  const [settings, setSettings] = useRecoilState(settingsState);
  const loginPasswordTextInput = useRef<TextInput>(null);
  const navigation = useNavigation<NavigationProp<MainStackParamList>>();

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (!isLoggedIn() ? <Button
        testID='btn_skip'
        title='Skip'
        type="clear" onPress={() => {
          navigation.goBack();
          closeModal();
          setSettings({...settings, shouldShowWelcome: false});
        }} /> : undefined),
    });
  }, []);

  const onLogin = async () => {
    try {
      // linkWithCredential(auth?.currentUser, EmailAuthProvider.credential(
      //     email, password));
      await auth?.signInWithEmailAndPassword(email, password);
      analytics?.logLogin({method: 'email'});
      close();
      closeModal();
    } catch (e) {
      setError((e as Error).message.split(' ').slice(1).join(' '));
    }
  };

  return <View style={{
    padding: 16,
    paddingBottom: 32,
    backgroundColor:
    theme.colors.white,
    alignItems: 'center',
    flex: 1}}
  >
    <Text style={{fontSize: 24, fontWeight: 'bold', marginVertical: 24}}>
      {mode === 'login' ? 'Login' : 'Create Account'}
    </Text>
    {mode === 'login' && <Text>
      Login to save your data and sync across devices.
    </Text>}
    {error && <Text
      style={{color: theme.colors.error, marginVertical: 16}}>
        Error: {error}
    </Text>}

    {mode === 'login' ? <View style={{
      flex: 1,
      width: '100%',
      maxWidth: 400,
    }}>
      <Input
        testID='login_email'
        value={email}
        placeholder="Email"
        onChangeText={(text) => setEmail(text)}
        onSubmitEditing={() => {
          loginPasswordTextInput.current?.focus();
        }} />
      <TextInput
        testID='login_password'
        secureTextEntry={true}
        value={password}
        placeholder="Password"
        onChangeText={(text) => setPassword(text)}
        style={{
          borderWidth: 1,
          padding: 12,
          borderColor: theme.colors?.border,
          marginBottom: 16,
          color: theme.colors?.black,
        }}
        underlineColorAndroid="transparent"
        ref={loginPasswordTextInput}
        onSubmitEditing={onLogin} />
      <View style={{flexDirection: 'column', alignSelf: 'center'}}>
        <Button
          testID="btn_login_submit"
          title="Login" type="clear" onPress={onLogin} />
      </View>
    </View> : <View style={{
      flex: 1,
      width: '100%',
      maxWidth: 400,
    }}>
      <KeyboardAvoidingView behavior="padding">
        <TextInput
          testID='new_account_email'
          secureTextEntry={false}
          value={email}
          keyboardType='email-address'
          placeholder="Email"
          autoCapitalize='none'
          onChangeText={(text) => setEmail(text)}
          style={{
            marginTop: 16,
            borderWidth: 1,
            padding: 12,
            borderColor: theme.colors?.border,
            color: theme.colors?.black,
            marginBottom: 12,
          }}
          underlineColorAndroid="transparent" />
        <TextInput
          testID='new_account_password'
          secureTextEntry={true}
          value={password}
          placeholder="Password"
          onChangeText={(text) => setPassword(text)}
          style={{
            borderWidth: 1,
            padding: 12,
            borderColor: theme.colors?.border,
            color: theme.colors?.black,
            marginBottom: 12,
          }}
          underlineColorAndroid="transparent" />
        <TextInput
          testID='new_account_password_retype'
          secureTextEntry={true}
          value={passwordRetyped}
          placeholder="Confirm Password"
          onChangeText={(text) => setPasswordRetyped(text)}
          style={{
            borderWidth: 1,
            padding: 12,
            borderColor: theme.colors?.border,
            marginBottom: 16,
            color: theme.colors?.black,
          }}
          underlineColorAndroid="transparent" />
      </KeyboardAvoidingView>
      <View style={{
        flexDirection: 'column', alignSelf: 'center',
        flex: 1,
      }}>
        <Text style={{textAlign: 'center', marginVertical: 16}}>
          By continuing, you acknowledge that you understand the
          {' '}<Text style={{color: theme.colors.primary}} onPress={() => {
            Linking.openURL('https://papershelf.app/privacypolicy/');
          }}>Privacy Policy</Text>
        </Text>
        <Button
          testID="btn_create_account_submit"
          title="Create" type="clear"
          onPress={async () => {
            try {
              if (password !== passwordRetyped) {
                throw Error(' Passwords do not match');
              }
              const user = await auth?.createUserWithEmailAndPassword(
                  email,
                  password,
              );
              user?.user.sendEmailVerification();
              dialog(
                  'Verification Email Sent',
                  'Please check your email to verify your account.');
              analytics?.logSignUp({method: 'email'});
              close();
            } catch (e) {
              setError((e as Error).message.split(' ').slice(1).join(' '));
            }
          }}
        />
      </View>

    </View>}
    {mode === 'login' &&
    <View style={{flexDirection: 'row', alignSelf: 'center'}}><Button
      title="Forgot Password?"
      titleStyle={{fontSize: 16}}
      type="clear"
      onPress={() => {
        showModal((close) => <Prompt
          title='Forgot Password'
          subTitle='Please enter your registered email to recover your account.'
          submitTitle='RESET PASSWORD'
          initialValue={email}
          onCancel={close}
          onSubmit={async (email) => {
            try {
              await auth?.sendPasswordResetEmail(email);
              dialog('Please check your email for instructions' +
            ' to reset password.');
              close();
            } catch (e) {
              dialog((e as Error).message.split(' ').slice(1).join(' '));
            }
          }}
        />, {
          maxWidth: 400,
        });
      }} /></View>}
    <View style={{
      flexDirection: 'row',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Text style={{fontSize: 16}}>
        {mode === 'signup' ? 'Have an Account? ' : 'Don\'t have an account?'}
      </Text>
      <Button
        testID='btn_create_account'
        title={mode === 'login' ? 'Create Account' : 'Login'}
        titleStyle={{fontSize: 16}}
        containerStyle={{padding: 0, margin: 0}}
        style={{padding: 0, margin: 0}}
        buttonStyle={{padding: 0, margin: 0}}
        type="clear" onPress={() => {
          setMode(mode === 'login' ? 'signup' : 'login');
          setError(undefined);
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        }} />
    </View>
  </View>;
};
