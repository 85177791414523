import {
  useNavigation,
} from '@react-navigation/native';
import {StackNavigationProp} from '@react-navigation/stack';
import {
  Button, ButtonGroup, Overlay,
  Text, useTheme} from '@rneui/themed';
import Fuse from 'fuse.js';
import React, {useContext, useEffect, useState} from 'react';
import {
  FlatList,
  LayoutAnimation,
  Pressable,
  RefreshControl,
  View,
} from 'react-native';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

import Api from '../common/api';
import Collection from '../common/collection';
import {Note} from '../common/note';
import Paper, {
  paperSearchKeys, PartialPaper, searchPaper,
} from '../common/paper';
import {AppContext, ListDisplayType, NavigationContext} from '../context';
import {infoNavigationRef, MainStackParamList} from '../Main';
import {dialog} from '../platform/dialog';
import {analytics} from '../platform/firebase';
import {
  IconAddCollection,
  IconClose,
  IconDelete,
  IconPublicCollection,
  IconSearch,
  IconSort,
} from '../platform/icons';
import {toastError} from '../platform/toast';
import {
  allPapersState,
  currentCollectionState,
  currentPaperState,
  isFetchingPaperState,
  isSearchModeState,
  listDisplayTypeState,
  paperFuseState,
  searchQueryState,
  settingsState,
  webSearchQueryState,
} from '../recoil/atoms';
import {
  isPhoneState,
  isWebState,
  layoutState,
  paperListItemsState,
  paperSearchListItemsState,
  realmState,
} from '../recoil/selectors';
import {GeneralSortBy} from '../screens/Settings/General';
import ActivityIndicator from './ActivityIndicator';
import PaperListHeader from './PaperListHeader';
import PaperListItem from './PaperListItem';
import {ItemKind, PreferenceItem} from './Preferences';
import {Toolbar, ToolbarItem} from './ToolbarItem';

type PaperListPaneProps = {
  // eslint-disable-next-line react/require-default-props
  onLongPress?: () => void;
  onPaperSelected?: (p: PartialPaper) => void;
  highlightCurrentPaper: boolean;
};

export type PaperListHandle = {
  refresh: (reload: boolean) => void;
};

type ListTabProps = {
  searchResultTypeOptions: Record<string, ListDisplayType>;
};

const ListTab = ({
  searchResultTypeOptions,
}: ListTabProps) => {
  const {theme} = useTheme();
  const [listDisplayType, setListDisplayType] =
    useRecoilState(listDisplayTypeState);

  return (
    <View
      style={{
        backgroundColor: theme.colors?.white,
        borderRightWidth: 1,
        borderRightColor: theme.colors?.border,
        marginTop: 8,
        marginBottom: 8,
      }}
    >
      <ButtonGroup
        buttons={Object.keys(searchResultTypeOptions)}
        buttonStyle={{
          backgroundColor: theme.colors.white,
        }}
        textStyle={{
          fontSize: 12,
          color: theme.colors.black,
        }}
        selectedButtonStyle={{
          backgroundColor: theme.colors.backgroundHighlight,
        }}
        selectedTextStyle={{
          fontSize: 12,
          color: theme.colors.black,
          fontWeight: 'bold',
        }}
        selectedIndex={Object.values(searchResultTypeOptions).indexOf(
            listDisplayType,
        )}
        onPress={(selectedIndex) => {
          setListDisplayType(
              Object.values(searchResultTypeOptions)[selectedIndex],
          );
        }}
        containerStyle={{height: 32}}
      />
    </View>
  );
};

type EmptyListProps = {
  isLoading: boolean;
  isEmpty?: boolean;
  isCollectionSelected?: boolean;
  isLibraryEmpty?: boolean;
};

const EmptyList = ({
  isLoading = true,
  isEmpty = false,
  isCollectionSelected = false,
  isLibraryEmpty = false,
}: EmptyListProps) => {
  const {theme} = useTheme();
  const {navigate} = useContext(NavigationContext);
  const [isSearchMode, setIsSearchMode] = useRecoilState(isSearchModeState);
  const setListDisplayType = useSetRecoilState(listDisplayTypeState);
  return (
    <View
      style={{
        justifyContent: 'center',
        flexGrow: 1,
        backgroundColor: theme.colors?.white,
      }}
    >
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        isEmpty &&
        (isSearchMode ? (
          <Text style={{padding: 16, textAlign: 'center'}}>
            No results found.
          </Text>
        ) : isCollectionSelected ? (
          <Text style={{padding: 16, textAlign: 'center'}}>
            This collection is empty.
          </Text>
        ) : (
          isLibraryEmpty && (
            <>
              <Text style={{margin: 16, textAlign: 'center', fontSize: 16}}>
                Your library is empty.
              </Text>
              <Button
                type="clear" title="Browse public collections"
                icon={<IconPublicCollection color={theme.colors?.primary} />}
                titleStyle={{fontSize: 16, marginLeft: 8}}
                onPress={() => {
                  navigate('PublicCollections', {screen: 'Main'});
                }} />
              <Button
                type="clear" title="Find a paper"
                icon={<IconSearch color={theme.colors?.primary} />}
                titleStyle={{fontSize: 16, marginLeft: 8}}
                onPress={() => {
                  setIsSearchMode(true);
                  setListDisplayType(ListDisplayType.WebSearch);
                }} />
            </>
          )
        ))
      )}
    </View>
  );
};

type SelectionToolbarProps = {
  currentPapers: string[];
  currentCollection?: Collection;
  onClose: () => void;
};

const SelectionToolbar = ({
  currentPapers,
  currentCollection,
  onClose,
}: SelectionToolbarProps) => {
  const {
    removePaperFromCollection,
    removePaperFromLibrary,
  } = useContext(AppContext);

  /**
   * remove all selected papers
   */
  const remove = async () => {
    dialog(
        'Remove',
        `Do you want to remove seletected ${currentPapers.length} paper(s)?`,
        [
          ...(currentCollection ?
          [
            {
              text: 'Remove from Collection',
              onPress: async () => {
                await Promise.all(
                    currentPapers.map((pid) =>
                      removePaperFromCollection(pid, currentCollection),
                    ),
                );
                onClose();
              },
            },
          ] :
          []),
          {
            text: 'Remove from Library',
            onPress: async () => {
              await removePaperFromLibrary(currentPapers);
              onClose();
            },
          },
          {text: 'Cancel', style: 'cancel'},
        ],
    );
  };

  return (
    <Toolbar borderTop key="multipleSelecToolbar">
      <ToolbarItem icon={IconDelete} title={'Remove'} onPress={remove} />
      {false && (
        <ToolbarItem icon={IconAddCollection} title="Add to Collection" />
      )}
      <View style={{flex: 1}} />
      <ToolbarItem icon={IconClose} onPress={onClose} />
    </Toolbar>
  );
};

type PaperListProps = {
  onRefresh?: () => Promise<void>;
  papers?: PartialPaper[],
  collection?: Collection,
  /**
   * PaperList needs to modify the current collection when the collection's
   * papers are lazy loaded.
   */
  setCollection?: (c?: Collection) => void,
  onPaperSelected?: (p: PartialPaper) => void,
  highlightPaperId?: string;
  ListHeaderComponent?: React.ReactElement,
  emptyListComponent?: React.ReactElement,
}

type CollectionTabName = 'papers' | 'notes' | 'settings';

export const PaperList = ({
  onRefresh,
  papers,
  collection,
  setCollection,
  onPaperSelected,
  highlightPaperId,
  ListHeaderComponent,
  emptyListComponent,
}: PaperListProps) => {
  const {theme} = useTheme();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const allPapers = useRecoilValue(allPapersState);
  const isFetching = useRecoilValue(isFetchingPaperState);
  const {isPaperInLibrary} = useContext(AppContext);

  useEffect(() => {
    if (collection?.publicCollectionKey || collection?.isPublic) {
      setCanLoadMore(true);
      loadNextPage();
    }
  }, [collection]);

  /**
   * Lazy load papers in a collection
   */
  async function loadNextPage() {
    if (!setCollection) return;
    const c = collection;
    if (!c) return;
    if (c.publicCollectionKey || c.isPublic) {
      const nextPapers = await Api.Collection.getPublicCollectionPapers(
          c.publicCollectionKey || c.key, 20, c.paperIds?.length);
      if (nextPapers.length > 0) {
        const updatedCollection = {
          ...c,
          paperIds: [...c.paperIds, ...nextPapers.map((p) => p.id)],
          papers: {
            ...c.papers,
            ...Object.fromEntries(nextPapers.map((p) => [p.id, p])),
          },
        };
        setCollection(updatedCollection);
      } else {
        setCanLoadMore(false);
      }
    } else {
      setCanLoadMore(false);
    }
  }

  const renderPaperItem = (
      {item: paper}: { item: PartialPaper },
      showChannels = false) => (
    <PaperListItem
      paper={paper}
      showChannels={showChannels}
      selected={
        highlightPaperId === paper.id
        // highlightCurrentPaper &&
        // (isSelectionMode ?
        //   currentPapers.includes(paper.id) :
        //   paper.id === currentPaper?.id)
      }
      onPaperSelected={() => {
        onPaperSelected && onPaperSelected(paper);
        analytics?.logViewItem({
          items: [{
            item_list_id: collection?.key || 'library',
            item_list_name: collection?.name || 'Library',
            item_id: paper.id,
            item_name: paper.title,
          }],
        });
        // if (isSelectionMode) {
        //   if (!currentPapers.includes(paper.id)) {
        //     setCurrentPapers([...currentPapers, paper.id]);
        //   } else {
        //     if (currentPapers.length === 1) setIsSelectionMode(false);
        //     setCurrentPapers(currentPapers.filter((p) => p !== paper.id));
        //   }
        // }
      }}
      loading={isFetching[paper.id]}
      onLongPress={() => {
        // if (!isSelectionMode) {
        //   setIsSelectionMode(true);
        //   setCurrentPapers([paper.id]);
        // }
        // if (onLongPress) {
        //   // onLongPress();
        //   // setCurrentPaper(paper);
        // }
      }}
      inLibrary={isPaperInLibrary(paper.id)}
      addPaperToLibrary={async () => {/**/}}
    />
  );

  const _getPapers = (): PartialPaper[] => {
    if (collection) {
      return collection.paperIds.map(
          (pid) => (allPapers[pid] || collection.papers[pid]) as PartialPaper)
          .filter((p) => !!p);
    } else {
      return papers || [];
    }
  };

  return <FlatList
    keyExtractor={(it) => it.id}
    data={_getPapers()}
    // removeClippedSubviews={true}
    renderItem={renderPaperItem}
    extraData={{
      highlightPaperId,
      collection,
      isFetching,
    }}
    refreshControl={onRefresh && <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        if (!onRefresh) return;
        setRefreshing(true);
        onRefresh().finally(() => setRefreshing(false));
      }}
      tintColor={theme.colors.black} />}
    // viewabilityConfigCallbackPairs={
    //   viewabilityConfigCallbackPairs.current
    // }
    onEndReachedThreshold={0.5}
    onEndReached={() => {
      if (canLoadMore) loadNextPage();
    }}
    ListHeaderComponent={ListHeaderComponent}
    contentContainerStyle={{flexGrow: 1}}
    ListEmptyComponent={
      <View style={{flex: 1}}>
        {canLoadMore ? <EmptyList isLoading={true} /> : emptyListComponent}
      </View>
    }
  />;
};

const CollectionTabs = ({tab, setTab, tabs}: {
  tab: CollectionTabName,
  setTab: (t: CollectionTabName) => void,
  tabs: CollectionTabName[],
}) => {
  const {theme} = useTheme();
  const currentCollection = useRecoilValue(currentCollectionState);
  const allPapers = useRecoilValue(allPapersState);
  const layout = useRecoilValue(layoutState);
  const navigation = useNavigation<StackNavigationProp<MainStackParamList>>();

  return <View style={{
    backgroundColor: theme.colors.white,
    paddingHorizontal: 16,
    paddingVertical: 12,
    flexDirection: 'row',
    borderColor: theme.colors.border,
    borderBottomWidth: 1,
  }}>
    {tabs.map((t, idx) => {
      const color = (tab === t ? theme.colors.black : theme.colors.disabled);
      let title;
      if (t === 'papers') {
        const numPapers = currentCollection ?
          (currentCollection.publicInfo?.numPapers ||
            currentCollection?.paperIds?.length || 0) :
          Object.keys(allPapers).length;
        title = `Papers (${numPapers})`;
      } else if (t === 'notes') {
        title = 'Notes';
      }
      return <View key={t} style={{flexDirection: 'row'}}>
        <Pressable onPress={() => setTab(t)}>
          <Text
            style={{color}}
          >{title}</Text>
        </Pressable>
        {idx !== tabs.length - 1 &&
        <Text style={{color: theme.colors.disabled}}>  |  </Text>}
      </View>;
    })}
    <View style={{flex: 1}} />
    <Pressable onPress={() => {
      if (layout === 'one-column') {
        navigation.navigate(
            'Preferences', {
              screen: 'CollectionDetails',
              params: {collectionKey: currentCollection?.key}});
      } else {
        if (infoNavigationRef.isReady()) {
          infoNavigationRef.navigate(
              'CollectionInfo', {collectionKey: currentCollection?.key});
        }
      }
    }}>
      <Text
        style={{color: theme.colors.disabled}}
      >Settings</Text>
    </Pressable>
  </View>;
};

const PaperListPane = ({
  // onLongPress,
  highlightCurrentPaper,
  onPaperSelected,
}: PaperListPaneProps): JSX.Element => {
  const {theme} = useTheme();

  const paperListItems = useRecoilValue(paperListItemsState);
  const paperSearchListItems = useRecoilValue(paperSearchListItemsState);
  const [settings, setSettings] = useRecoilState(settingsState);
  const setFuse = useSetRecoilState(paperFuseState);

  const {
    loadAppData,
  } = useContext(AppContext);
  const {showModal} = useContext(NavigationContext);
  const isPhone = useRecoilValue(isPhoneState);
  const isWeb = useRecoilValue(isWebState);

  const [isSearchMode, setIsSearchMode] = useRecoilState(isSearchModeState);

  const [isSelectionMode, setIsSelectionMode] = useState<boolean>(false);
  const [currentPapers, setCurrentPapers] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useRecoilState(searchQueryState);
  const [webSearchQuery, setWebSearchQuery] =
    useRecoilState(webSearchQueryState);
  const [_webSearchPage, setWebSearchPage] = useState<number>(0);
  const [webSearchPapers, setWebSearchPapers] = useState<PartialPaper[]>([]);
  const [_webSearchCanLoadMore, setWebSearchCanLoadMore] =
    useState<boolean>(true);

  const [isLoadingOnlineResults, setIsLoadingOnlineResults] =
    useState<boolean>(false);

  const [isOverlayMenuVisible, setIsOverlayMenuVisible] =
    useState<boolean>(false);

  const [searchResultTypeOptions, setSearchResultTypeOptions] = useState<
    Record<string, ListDisplayType>
  >({});

  const [tab, setTab] = useState<CollectionTabName>('papers');

  const allPapers = useRecoilValue(allPapersState);
  const currentPaper = useRecoilValue(currentPaperState);
  const [currentCollection, setCurrentCollection] =
    useRecoilState(currentCollectionState);
  const [listDisplayType, setListDisplayType] =
    useRecoilState(listDisplayTypeState);
  const realm = useRecoilValue(realmState);

  useEffect(() => {
    setTab('papers');
  }, [currentCollection?.key]);

  /**
   * Populates paper list with search results
   * @param query - search query
   * @param papers - current list of papers
   * @param page - page number
   * @param canLoadMore - if more results can be loaded
   */
  const onlineSearch = (
      query: string,
      papers: Paper[],
      page = 0,
      canLoadMore = true,
  ) => {
    if (query === '' || !canLoadMore) {
      setIsLoadingOnlineResults(false);
      return;
    }
    setIsLoadingOnlineResults(true);

    const resPapers: PartialPaper[] = papers as PartialPaper[];
    const updateResults = (res: Paper[], source: string) => {
      for (const p of res) {
        const rp = resPapers.find((rp) => rp.id === p.id);
        if (!rp) {
          p.channels.push(source);
          resPapers.push(p as PartialPaper);
        } else {
          rp.channels?.push(source);
        }
      }
      const fuse = new Fuse(resPapers, {
        keys: paperSearchKeys,
        threshold: 1.0,
        includeMatches: true,
        minMatchCharLength: 3,
        useExtendedSearch: true,
        findAllMatches: true,
        ignoreLocation: true,
      });
      const fusePapers = fuse.search(`'"${query}"`).map((r) => ({
        ...r.item,
        matches: Object.fromEntries(
            paperSearchKeys.map((k) => {
              const match = r.matches?.find((m) => m.key === k);
              return [k, match && {
                indices: match.indices,
                text: match.value,
              }];
            })),
      }));
      const fusePaperIds = fusePapers.map((p) => p.id);
      setWebSearchPapers([
        ...fusePapers,
        ...resPapers.filter((p) => !fusePaperIds.includes(p.id))
            .sort((a, b) => (b.numCitations || 0) - (a.numCitations || 0)),
      ]);
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      if (resPapers.length === 0) setWebSearchCanLoadMore(false);
      analytics?.logSearch({
        search_term: query,
      });
    };

    searchPaper(query, settings.searchPaperSources, updateResults, page)
        .then(() => {
          setIsLoadingOnlineResults(false);
          setWebSearchPage(page + 1);
        })
        .catch((e) => {
          toastError('Error loading papers: ' + e.message, e);
          setIsLoadingOnlineResults(false);
        });
  };

  useEffect(() => {
    // refresh fuse
    if (!isSearchMode) return;
    if (listDisplayType === ListDisplayType.LibrarySearch ||
        listDisplayType === ListDisplayType.CollectionSearch) {
      const initFuse = async () => {
        const papers = (await Promise.all(
            paperListItems.map((pp) => Api.Paper.local.load(pp.id, realm))))
            .filter((p) => !!p) as PartialPaper[];
        setFuse(new Fuse(papers, {
          keys: paperSearchKeys,
          threshold: 0.5,
          includeMatches: true,
          minMatchCharLength: 3,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
        }));
      };
      initFuse();
    }
  }, [listDisplayType, isSearchMode]);

  const onSearchModeChanged = () => {
    if (isSearchMode) {
      if (currentCollection) {
        setSearchResultTypeOptions({
          'In Collection': ListDisplayType.CollectionSearch,
          'In Library': ListDisplayType.LibrarySearch,
          'Web Search': ListDisplayType.WebSearch,
        });
        if (listDisplayType === ListDisplayType.Regular) {
          setListDisplayType(ListDisplayType.CollectionSearch);
        }
      } else {
        setSearchResultTypeOptions({
          'In Library': ListDisplayType.LibrarySearch,
          'Web Search': ListDisplayType.WebSearch,
        });
        if (
          listDisplayType === ListDisplayType.Regular ||
          listDisplayType === ListDisplayType.CollectionSearch
        ) {
          setListDisplayType(ListDisplayType.LibrarySearch);
        }
      }
    } else {
      setListDisplayType(ListDisplayType.Regular);
    }
  };

  // Refresh search results if query changed
  useEffect(() => {
    setWebSearchPage(0);
    setWebSearchPapers([]);
    setWebSearchCanLoadMore(true);
    onlineSearch(webSearchQuery, []);
  }, [webSearchQuery]);

  useEffect(() => {
    if (listDisplayType == ListDisplayType.WebSearch) {
      setWebSearchQuery(searchQuery);
    }
  }, [listDisplayType]);

  useEffect(onSearchModeChanged, [isSearchMode]);

  useEffect(() => {
    if (webSearchQuery) setListDisplayType(ListDisplayType.WebSearch);
  }, [webSearchQuery]);

  // const getWebSearchLink = (kw: string) => (
  //   <Text
  //     style={{ marginTop: 16, textAlign: 'center', color: 'blue' }}
  //     onPress={() => {
  //       setIsSearchMode(true);
  //       setLiveSearchQuery(kw);
  //       setSearchQuery(kw);
  //       setListDisplayType(ListDisplayType.SearchOnline);
  //     }}
  //   >
  //     {'"'}
  //     {kw}
  //     {'"'}
  //   </Text>
  // );

  const webSearchList =
    isLoadingOnlineResults || webSearchPapers.length === 0 ? (
      <EmptyList
        isLoading={isLoadingOnlineResults}
        isEmpty={webSearchPapers.length === 0}
        isCollectionSelected={currentCollection !== null}
        isLibraryEmpty={Object.keys(allPapers).length === 0}
      />
    ) : (
      <PaperList
        papers={webSearchPapers}
        onPaperSelected={onPaperSelected}
        highlightPaperId={highlightCurrentPaper ? currentPaper?.id : undefined}
      />
    );

  const libraryList =
      <PaperList
        papers={isSearchMode ? paperSearchListItems : paperListItems}
        collection={currentCollection?.publicCollectionKey ?
            currentCollection : undefined}
        setCollection={setCurrentCollection}
        onRefresh={async () => {
          try {
            return await loadAppData();
          } catch (e) {
            toastError('Could not refresh. \nPlease try again later.', e);
          }
        }}
        onPaperSelected={onPaperSelected}
        highlightPaperId={highlightCurrentPaper ? currentPaper?.id : undefined}
        emptyListComponent={<EmptyList
          isLoading={false}
          isEmpty={!isSearchMode ? paperListItems.length === 0 :
            paperSearchListItems.length === 0}
          isCollectionSelected={!!currentCollection}
          isLibraryEmpty={Object.keys(allPapers).length === 0}
        />}
      />;

  const noteList = <FlatList
    data={[] as Note[]}
    renderItem={({item}) => {
      return <View style={{padding: 8}}>
        <Text>{item.title}</Text>
      </View>;
    }}
  />;

  return (
    <View style={{
      height: '100%',
      backgroundColor: theme.colors.white,
    }}>
      <PaperListHeader
        isLoading={isLoadingOnlineResults}
        isSearchMode={isSearchMode}
        onChangeText={(value: string) => {
          setSearchQuery(value);
        }}
        searchQuery={searchQuery}
        onEndEditing={() => {
          if (!searchQuery) {
            setIsSearchMode(false);
          }
        }}
        onSubmitEditing={() => {
          if ((listDisplayType === ListDisplayType.LibrarySearch ||
              listDisplayType === ListDisplayType.CollectionSearch) &&
              paperSearchListItems.length === 0) {
            setWebSearchQuery(searchQuery);
          }
        }}
        onSearch={() => {
          setWebSearchQuery(searchQuery);
        }}
        onClear={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          setWebSearchQuery('');
          setSearchQuery('');
          setIsSearchMode(false);
        }}
        onCollectionClick={() => {
          // setCurrentPaper(null);
        }}
        onStartSearch={() => {
          LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
          setIsSearchMode(true);
          if (paperListItems.length === 0) {
            setListDisplayType(ListDisplayType.WebSearch);
          }
        }}
        onOpenMenu={() => {
          setIsOverlayMenuVisible(true);
        }}
      />
      <Overlay
        isVisible={isOverlayMenuVisible}
        onBackdropPress={() => setIsOverlayMenuVisible(false)}
        overlayStyle={{
          width: 200,
          position: 'absolute',
          top: 64,
          left: 132,
          padding: 0,
        }}>
        <PreferenceItem
          id='test'
          title='Sort'
          kind={ItemKind.Button}
          icon={IconSort}
          onPress={() => {
            setIsOverlayMenuVisible(false);
            showModal(
                (close) => (
                  <GeneralSortBy onChange={close} />
                ),
                {
                  title: 'Sort By',
                  width: 300,
                  height: 300,
                },
            );
          }}
          separator={false}
        />
        {(!isPhone && !isWeb &&
        (settings.layout === 'two-column' || settings.layout === 'auto')) &&
          <PreferenceItem
            id='single_column'
            kind={ItemKind.Button}
            title='Single Layout'
            onPress={() => {
              setIsOverlayMenuVisible(false);
              setSettings({...settings, layout: 'one-column'});
              LayoutAnimation.configureNext(
                  LayoutAnimation.Presets.easeInEaseOut);
            }}
            separator={false}
          />}
        {!isPhone && !isWeb && settings.layout === 'one-column' &&
        <PreferenceItem
          id='double_column'
          kind={ItemKind.Button}
          title='Side by Side'
          onPress={() => {
            setIsOverlayMenuVisible(false);
            setSettings({...settings, layout: 'two-column'});
            LayoutAnimation.configureNext(
                LayoutAnimation.Presets.easeInEaseOut);
          }}
          separator={false}
        />}
      </Overlay>
      {isSearchMode && (
        <ListTab
          searchResultTypeOptions={searchResultTypeOptions}
        />
      )}

      <View
        style={{
          flex: 1,
        }}
      >
        {currentCollection && <CollectionTabs
          tabs={['papers']}
          tab={tab} setTab={setTab} />}
        {(tab == 'papers' || !currentCollection) &&
          (listDisplayType === ListDisplayType.WebSearch ?
          webSearchList : libraryList)}
        {tab == 'notes' && noteList}

        {isSelectionMode && (
          <SelectionToolbar
            currentPapers={currentPapers}
            currentCollection={currentCollection}
            onClose={() => {
              setIsSelectionMode(false);
              setCurrentPapers([]);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default PaperListPane;
