import {Text, useTheme} from '@rneui/themed';
import React, {useContext, useEffect} from 'react';
import {
  FlatList,
  LayoutAnimation,
  Pressable,
  View,
} from 'react-native';
import {useRecoilValue} from 'recoil';

import {PdfHighlight} from '../common/paper';
import TextInput from '../components/TextInput';
import {dialog} from '../platform/dialog';
import {IconDelete, IconEdit, IconNoteAdd} from '../platform/icons';
import {uuid} from '../platform/misc';
import {currentPaperState} from '../recoil/atoms';
import {AnnotationContext} from '../screens/PaperView';
import {Toolbar, ToolbarItem} from './ToolbarItem';

const HighlightList = () => {
  const {theme} = useTheme();
  const {
    highlights,
    currentHighlight,
    setCurrentHighlight,
    updateHighlights,
  } = useContext(AnnotationContext);
  const [showCommentEditor, setShowCommentEditor] = React.useState(false);
  const [editorContent, setEditorContent] = React.useState('');
  const [titleEditorContent, setTitleEditorContent] = React.useState('');
  const paper = useRecoilValue(currentPaperState);

  const onEditComment = (hl: PdfHighlight) => {
    if (!paper) return;
    setCurrentHighlight(hl);
    setShowCommentEditor(true);
  };

  useEffect(() => {
    if (currentHighlight) {
      setEditorContent(currentHighlight.comment?.text || '');
      setTitleEditorContent(currentHighlight.content?.text || '');
    }
  }, [currentHighlight?.id]);

  return (
    <FlatList
      data={highlights}
      extraData={currentHighlight}
      keyExtractor={(item) => item.id}
      style={{backgroundColor: theme.colors.background}}
      ListHeaderComponent={() => (
        <View
          style={{
            padding: 8,
            backgroundColor: theme.colors.white,
            marginTop: 4,
            marginHorizontal: 4,
            borderWidth: 1,
            borderColor: theme.colors.border,
          }}
        >
          <Pressable
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => {
              updateHighlights([
                ...highlights,
                {
                  id: uuid(),
                  content: {
                    text: 'Untitled',
                    canEdit: true,
                  },
                  position: undefined,
                  comment: {text: '', emoji: ''},
                } as PdfHighlight,
              ]);
            }}
          >
            <IconNoteAdd />
            <Text> Add Note</Text>
          </Pressable>
        </View>
      )}
      renderItem={({item}) => (
        <View
          style={{
            padding: 0,
            backgroundColor: theme.colors.white,
            marginTop: 4,
            marginHorizontal: 4,
            borderWidth: 1,
            borderColor: theme.colors.border,
          }}
        >
          <Toolbar borderBottom={true}>
            <ToolbarItem
              fill
              title={item.position?.pageNumber?.toString() ||
                  item.position?.description || ''} />
            <ToolbarItem icon={IconEdit} onPress={() => {
              onEditComment(item);
            }} />
            <ToolbarItem icon={IconDelete} onPress={() => {
              if (!paper) return;
              dialog(
                  'Remove Note',
                  'Do you want to remove this note?',
                  [
                    {
                      text: 'Remove',
                      onPress: async () => {
                        setCurrentHighlight(null);
                        updateHighlights(
                            highlights.filter((h) => h.id !== item.id));
                      },
                      style: 'destructive',
                    },
                    {text: 'Cancel'},
                  ],
              );
            }} />
          </Toolbar>
          <View style={{padding: 8}}>
            <View
              style={{
                borderLeftWidth: 4,
                borderColor:
                  currentHighlight?.id === item.id ?
                    theme.colors.error :
                    theme.colors.border,
                paddingLeft: 4,
                flexDirection: 'row',
              }}
            >
              {showCommentEditor &&
              currentHighlight?.id === item.id && item?.content?.canEdit ?
              <TextInput
                style={{
                  flex: 1,
                  padding: 8,
                }}
                placeholder='Some Text'
                value={titleEditorContent}
                onChangeText={(val) => setTitleEditorContent(val)}
              /> : <Pressable
                onPress={() => {
                  setCurrentHighlight(item);
                  setShowCommentEditor(false);
                  LayoutAnimation.configureNext(
                      LayoutAnimation.Presets.easeInEaseOut,
                  );
                }}
                onLongPress={() => onEditComment(item)}
                style={{flex: 1}}
              ><Text
                  numberOfLines={currentHighlight?.id === item.id ? 10 : 3}
                  style={{fontStyle: 'italic'}}
                >
                  {item?.content?.text || '[Untitled]'}
                </Text></Pressable>}
            </View>

            {/* <TouchableOpacity
                onPress={() => {
                  if (!currentPaper) return;
                  setCurrentPaper({
                    ...currentPaper,
                    pdfHighlights: [
                      ...currentPaper.pdfHighlights,
                      highlight,
                    ],
                  } as Paper);
                  savePaperAndSync(currentPaper);
                }}><IconEdit /></TouchableOpacity> */}
            {currentHighlight?.id === item.id && showCommentEditor ? (
            <View
              style={{
                marginTop: 8,
                // borderTopWidth: 1,
                // borderColor: theme.colors.border,
              }}
            >
              <TextInput
                value={editorContent}
                onChangeText={(val) => setEditorContent(val)}
                style={{
                  borderWidth: 1,
                  borderColor: theme.colors.border,
                  padding: 8,
                  minHeight: 100,
                }}
                autoCapitalize={'sentences'}
                multiline={true}
              />
              <Toolbar>
                <View style={{flex: 1}} />
                <ToolbarItem
                  title="Discard"
                  width={100}
                  onPress={() => {
                    setShowCommentEditor(false);
                  }}
                />
                <ToolbarItem
                  title="Done"
                  width={100}
                  onPress={() => {
                    updateHighlights(
                        highlights.map((h) =>
                        h.id === item.id ?
                          {
                            ...h,
                            content: h.content?.canEdit ? {
                              ...h.content,
                              text: titleEditorContent,
                            } : h.content,
                            comment: {
                              text: editorContent,
                              emoji: '',
                            },
                          } :
                          h,
                        ),
                    );
                    setShowCommentEditor(false);
                  }}
                />
              </Toolbar>
            </View>
          ) : (
            <Pressable
              onPress={() => setCurrentHighlight(item)}
              onLongPress={() => onEditComment(item)}
              style={{marginTop: 6}}>
              {item?.comment?.text && (
                <Text
                  style={{
                    marginTop: 4,
                  }}
                  numberOfLines={3}
                >
                  {item?.comment?.text}
                </Text>
              )}
              {item?.comment?.emoji}
            </Pressable>
          )}
          </View>
        </View>
      )}
    />
  );
};

export default HighlightList;
