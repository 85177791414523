import {SourceKey} from 'paper-fetch';

import Collection from './collection';
import {defaultTags} from './constants';
import Paper from './paper';
import {TagProps} from './tag';

export type HomePapersSectionSettings = {
  key: string;
  title: string;
  query: string;
  visible: boolean;
};

export type HomeNewsSettings = {
  included: boolean;
  numItems: number;
};

export type HomePublicCollectionsSettings = {
  included: boolean;
  numItems: number;
};

export enum SortType {
  ByTitle = 'title',
  ByYear = 'year',
  ByCitation = 'citation',
  ByDateAdded = 'dateAdded',
  ByDateModified = 'dateModified',
  ByDateOpened = 'dateOpened',
}

export enum ToastFrequency {
  Regular = 'regular',
  Muted = 'muted',
  Verbose = 'verbose',
}

export type User = {
  uid: string | null;
};

export type PdfReaderType = 'default' | 'external' | 'webview';

export type Layout = 'auto' | 'one-column' | 'two-column' | 'three-column';

export type Settings = {
  layout: Layout;
  userDataPath: string;
  dataLocation: string;
  paperLocation: string;
  cachePdf: boolean;
  cacheExpiration: number;
  theme: string;
  useTab: boolean;
  autoDownload: boolean;
  paperListItemFormat: string;
  paperList: {
    headerFormat: string;
    contentFormat: string;
    content2Format: string;
    expandedHeaderFormat: string;
    expandedContentFormat: string;
    actionButtons: string[];
    sortBy: SortType;
    showTags: boolean;
    singleLine: boolean;
    shortAuthorNames: boolean;
  };
  paperTab: {
    max: number;
    headerFormat: string;
    contentFormat: string;
  };
  searchFields: string[];
  searchThreshold: number;
  view: {
    showSideBar: boolean;
  };
  defaultTags: string[];
  fetchPaperSources: SourceKey[];
  searchPaperSources: SourceKey[];
  sync: {
    enabled: boolean;
    method: string;
  };
  dateSaved: number;
  fetch: {
    auto: boolean;
    expiration: number;
    timeout: number;
  };
  pdfReader: PdfReaderType;
  pdfScrollDirection: 'vertical' | 'horizontal';
  showNotes: boolean;

  // Viewer
  experimentalPdfViewer: boolean;
  saveLastReadPage: boolean;
  useWebViewForHtml: boolean;
  showPaperViewToolbar: boolean;
  showWebNotificationBar: boolean;

  // API keys
  openAIKey?: string;

  // Misc
  migratedFromLocalStorage: boolean,
  shouldShowWelcome: boolean,
};

export const defaultSettings = {
  layout: 'auto',
  userDataPath: '',
  dataLocation: '',
  paperLocation: '',
  cachePdf: true,
  cacheExpiration: 15,
  theme: 'default',
  useTab: false,
  offlineAccess: false,
  autoDownload: false,
  paperListItemFormat:
    '<div style=\'margin-bottom: 5px\'>{aliasAndTitle}</div>' +
    '<small><div style=\'margin-bottom: 2px\'><i>{authorFull}</i></div>' +
    '<div>{venueAndYear} - Cited {numCitations}</div></small>',
  paperList: {
    headerFormat: '{aliasAndTitle}',
    contentFormat: '{authorShort}',
    content2Format: '{venueAndYear} - Cited by {numCitations}',
    expandedHeaderFormat: '{title} ({authorShort}, {venueAndYear})',
    expandedContentFormat: '{abstract}',
    actionButtons: ['star', 'add'],
    sortBy: SortType.ByDateAdded,
    showTags: true,
    singleLine: false,
    shortAuthorNames: false,
  },
  paperTab: {
    max: 4,
    headerFormat: '{title}',
    contentFormat: '{authorShort} ({venueAndYear})',
  },
  searchPaperSources: ['arxiv', 'semanticScholar'],
  fetchPaperSources: ['arxiv', 'semanticScholar', 'paperShelf', 'openReview'],
  searchFields: ['title', 'tags', 'authors', 'abstract'],
  searchThreshold: 0.4,
  view: {
    showSideBar: true,
  },
  defaultTags: [],
  pdfViewerToolbar: ['info', 'divider', 'zoomIn', 'zoomOut', 'divider', 'open'],
  dateSaved: 0,
  sync: {
    enabled: true,
    method: 'firebase',
  },
  fetch: {
    auto: true,
    expiration: 30, // in days
    timeout: 2000, // in miliseconds
  },
  pdfReader: 'default',
  pdfScrollDirection: 'vertical',
  saveLastReadPage: true,
  showNotes: false,

  // PDF Viewer
  experimentalPdfViewer: false,
  useWebViewForHtml: true,
  showPaperViewToolbar: true,
  showWebNotificationBar: true,

  // API keys
  openAIKey: undefined,

  // Misc
  migratedFromLocalStorage: false,
  shouldShowWelcome: true,
} as Settings;

// store.clear();

export const defaultAppData = {
  papers: {},
  collections: [] as Collection[],
  tags: defaultTags as TagProps[],
  dateSaved: 0,
};

export type AppData = {
  papers: Record<string, Paper>;
  collections: Collection[];
  tags: TagProps[];
  dateSaved: number;
};
