import {Text} from '@rneui/themed';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {LayoutAnimation, View} from 'react-native';
import {useRecoilState, useRecoilValue} from 'recoil';

import Paper, {PdfHighlight} from '../common/paper';
import {SortType} from '../common/store';
import HighlightList from '../components/HighlightList';
import PdfViewer from '../components/PdfViewer';
import {Toolbar, ToolbarItem} from '../components/ToolbarItem';
import {AppContext, NavigationContext} from '../context';
import {isWeb} from '../platform';
import {analytics} from '../platform/firebase';
import {
  IconConfig, IconHighlight, IconText, IconZoomIn, IconZoomOut,
} from '../platform/icons';
import {settingsState} from '../recoil/atoms';
import {currentPaperState} from '../recoil/atoms/papers';
import {isElectronState, layoutState} from '../recoil/selectors';
import {HTMLPaperViewer} from './HTMLPaperView';

export const AnnotationContext = createContext<{
  currentHighlight: PdfHighlight | null;
  setCurrentHighlight:(h: PdfHighlight | null) => void;
  updateHighlights: (hs: PdfHighlight[]) => void;
  highlights: PdfHighlight[];
  setHighlights?: (hs: PdfHighlight[]) => void;
      }>({
        currentHighlight: null,
        setCurrentHighlight: () => {
          throw new Error('Function not implemented.');
        },
        updateHighlights: () => {
          throw new Error('Function not implemented.');
        },
        highlights: [],
        setHighlights: (_hs: PdfHighlight[]) => {
          throw new Error('Function not implemented.');
        },
      });

type PaperViewPropsType = {
  paper: Paper;
};

export const PaperViewComponent = ({paper}: PaperViewPropsType) => {
  const {
    savePaperAndSync,
    onPaperOrderChanged,
    isPaperInLibrary,
  } = useContext(AppContext);
  const {navigate} = useContext(NavigationContext);
  const [settings, setSettings] = useRecoilState(settingsState);
  const layout = useRecoilValue(layoutState);
  const isElectron = useRecoilValue(isElectronState);

  const [currentHighlight, setCurrentHighlight] =
    useState<PdfHighlight | null>(null);
  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);

  const updateHighlights = async (hs: PdfHighlight[]) => {
    const newPaper = {...paper, pdfHighlights: hs} as Paper;
    if (isPaperInLibrary(newPaper.id)) {
      await savePaperAndSync(newPaper);
    }
  };

  useEffect(() => {
    setHighlights(([...paper?.pdfHighlights || []])
        // .filter((h) => h.position?.pageNumber === page)
        .sort((a, b) => {
          if (a.position?.boundingRect === undefined &&
              b.position?.boundingRect) return -1;
          if (b.position?.boundingRect === undefined &&
              a.position?.boundingRect) return 1;

          return (a.position?.boundingRect?.x1 || 0) -
              (b.position?.boundingRect?.x1 || 0);
        }));
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }, [paper?.pdfHighlights]);

  const highlightList = paper &&
    <HighlightList />;

  useEffect(() => {
    // update dateOpened
    analytics?.logEvent('read_paper_pdf', {
      paperId: paper.id,
      paperTitle: paper.title,
    });

    if (isPaperInLibrary(paper.id) &&
      (paper.dateOpened || 0) < Date.now() - 5000) {
      savePaperAndSync({
        ...paper,
        dateOpened: Date.now(),
      });
      return () => {
        onPaperOrderChanged(SortType.ByDateOpened);
      };
    }
  }, [paper.id]);

  return (
    <AnnotationContext.Provider value={{
      currentHighlight,
      setCurrentHighlight,
      updateHighlights,
      highlights,
      setHighlights,
    }}>
      <View testID="pdf_viewer_container" style={{flex: 1}}>
        {settings.showPaperViewToolbar && <Toolbar borderBottom={true}>
          <ToolbarItem
            active={settings.showNotes}
            marginRight={true}
            icon={IconHighlight} onPress={() => {
            // if (layout === 'one-column') {
            //   navigate('Notes');
            // } else {
              setSettings({...settings, showNotes: !settings.showNotes});
            // }
            }} />
          <ToolbarItem marginRight={true} icon={IconZoomOut} onPress={() => {
            savePaperAndSync({...paper, zoom: paper.zoom - 10}, false);
          }} />
          <ToolbarItem marginRight={true} title={`${paper.zoom}%`} width={64} />
          <ToolbarItem marginRight={true} icon={IconZoomIn} onPress={() => {
            savePaperAndSync({...paper, zoom: paper.zoom + 10}, false);
          }} />
          <View style={{flexGrow: 1}}></View>
          <ToolbarItem
            active={paper.viewer === 'html'}
            marginRight={true}
            icon={IconText}
            disabled={isWeb && !isElectron}
            onPress={() => {
              savePaperAndSync({
                ...paper,
                viewer: paper.viewer === 'html' ? 'pdf' : 'html',
              }, false);
            }}
          />
          <ToolbarItem icon={IconConfig} onPress={() => {
            navigate('Preferences', {screen: 'PdfViewer'});
          }} />
        </Toolbar>}
        <View style={{flex: 2, flexDirection: 'row'}}>
          <View style={{flex: 2}}>
            {paper && (
          paper.viewer === 'pdf' || (isWeb && !isElectron) ? (
            <PdfViewer paper={paper} />
          ) : (paper.viewer === 'html' ?
            <HTMLPaperViewer paper={paper} /> : (
            <Text style={{padding: 16, textAlign: 'center'}}>
              Could not retrieve the paper. Please try again later.
            </Text>
          ))
            )}
          </View>
          {settings.showNotes && layout !== 'one-column' &&
            <View style={{
              flex: 1, maxWidth: 300}}
            >
              {highlightList}
            </View>}
        </View>
        {settings.showNotes && layout === 'one-column' &&
        <View style={{flex: 1}}>{highlightList}</View>}
      </View>
    </AnnotationContext.Provider>
  );
};

export const PaperViewScreen = (): JSX.Element => {
  const currentPaper = useRecoilValue(currentPaperState);
  return (
      currentPaper ? <PaperViewComponent paper={currentPaper} /> : <></>
  );
};

export default PaperViewScreen;

