import {LinearProgress, Text, useTheme} from '@rneui/themed';
import React, {useEffect, useState} from 'react';
// import Pdf from '../components/Pdf';
import {View} from 'react-native';
import {useRecoilState} from 'recoil';

import Paper, {PdfHighlight} from '../common/paper';
import {IconClose, IconHighlight} from '../platform/icons';
import {uuid} from '../platform/misc';
import {settingsState} from '../recoil/atoms';
import {
  AreaHighlight,
  Highlight,
  IHighlight,
  PdfHighlighter,
  PdfLoader,
} from './PdfView';
import PdfWebViewer from './PdfWebViewer';
import {Toolbar, ToolbarItem} from './ToolbarItem';

type PdfViewerProps = {
  paper: Paper | null;
  updateHighlights: (hs: PdfHighlight[]) => void;
};

const PdfViewer = ({
  paper = null,
  updateHighlights,
}: PdfViewerProps): JSX.Element => {
  const [errorMsg, setErrorMsg] = useState<string>();
  const [_numPages, _setNumPages] = useState<number>(0);

  const [currentHighlight, setCurrentHighlight] =
    useState<PdfHighlight | null>(null);
  const [highlights, setHighlights] = useState<PdfHighlight[]>([]);
  const [settings, setSettings] = useRecoilState(settingsState);

  useEffect(() => {
    setErrorMsg(undefined);
    setHighlights(paper?.pdfHighlights || []);
  }, [paper?.id]);

  const addCurrentHighlight = async () => {
    if (!paper) return;
    if (!currentHighlight) return;
    if (highlights.find((h) => h.id === currentHighlight.id)) return;
    updateHighlights([
      ...highlights,
      currentHighlight,
    ]);
  };

  const noPaperView = (<View
    style={{
      flex: 1,
      justifyContent: 'center',
      padding: 16,
      alignItems: 'center',
    }}
  >
    <Text>{errorMsg || 'No paper selected.'}</Text>
  </View>);

  // TODO(trungdang): Add something to add highlight
  const toolbar = <Toolbar borderBottom={true}>
    <ToolbarItem
      icon={IconHighlight}
      disabled={!!highlights.find((h) => h.id === currentHighlight?.id) ||
        !currentHighlight?.content?.text}
      onPress={addCurrentHighlight} />
    <View style={{flex: 1}} />
    <ToolbarItem
      icon={IconClose}
      onPress={() => {
        setSettings({
          ...settings,
          showNotes: !settings.showNotes,
        });
      }} />
  </Toolbar>;

  const pdfLoader = paper && settings.experimentalPdfViewer ?
    <PdfWebViewer paper={paper} /> : <PdfLoader
      url={paper?.pdfUrl || ''}
      beforeLoad={<LinearProgress />}
    >
      {(pdfDocument) => (
        <PdfHighlighter
          pdfDocument={pdfDocument}
          onScrollChange={() => {
            document.location.hash = '';
          } }
          highlights={highlights as IHighlight[]}
          onSelectionFinished={(position, content) => {
            setCurrentHighlight({
              id: uuid(),
              content,
              position,
              comment: undefined,
            });
            return null;
          } }
          highlightTransform={(
              highlight,
              index,
              setTip,
              hideTip,
              viewportToScaled,
              screenshot,
              isScrolledTo) => {
            const isTextHighlight = !(
              highlight.content && highlight.content.image);

            const component = isTextHighlight ? (
            <Highlight
              isScrolledTo={isScrolledTo}
              position={highlight.position}
              comment={highlight.comment}
              onClick={() => {
                setCurrentHighlight(highlight);
              } } />
          ) : (
            <AreaHighlight
              isScrolledTo={isScrolledTo}
              highlight={highlight}
              onChange={(_boundingRect) => {
                // updateHighlight(
                //     highlight.id,
                //     {boundingRect: viewportToScaled(boundingRect)},
                //     {image: screenshot(boundingRect)},
                // );
              } } />
          );

            return (
              component
            // <Popup
            //   popupContent={
            //     <div className="Highlight__popup">
            //       <Text>{highlight.id}</Text>
            //     </div>
            //   }
            //   onMouseOver={(popupContent) =>
            //     setTip(highlight, (highlight) => popupContent)
            //   }
            //   onMouseOut={hideTip}
            //   key={index}
            // >{component}</Popup>
            );
          }}
          scrollRef={function(
              _scrollTo: (highlight: IHighlight) => void): void {
          // throw new Error('Function not implemented.');
          }}
          enableAreaSelection={function(_event: MouseEvent): boolean {
          // throw new Error('Function not implemented.');
            return false;
          }} />
      )}
    </PdfLoader>;

  return errorMsg || !paper || !paper?.pdfUrl ? noPaperView :
  <View style={{flexDirection: 'column', flex: 1}}>
    <View style={{flexDirection: 'row', height: '100%'}}>
      <View style={{
        flex: 1,
      }}>
        {pdfLoader}
      </View>
    </View>
  </View>;
};

export default PdfViewer;
